import moment from 'moment';
// import sAction from "../index";
// import {calPlanReload} from "../exportConfig";
import {object} from "prop-types";

export default function calGetEvents(forced, way, userID, _callback){
    var self = this;
    var prefix = way?way+'/data/options':'calendar/filters';
    if(!self.calEvents.cssSelector){
        // TTT - tohle je kvuli dashletu (dost velka prasarna)
        // || $("#"+self.calEvents.cssSelector).length === 0
        return;
    }

    if(forced){
        let params = {"user_id" : userID, "date_range" : this.calEvents.date_range, "params" : {"sharedView" : self.calEvents.sharedView}};

        this.rest.post("calActivity", params, function(data){
            // $("#"+self.calEvents.cssSelector).fullCalendar("render");

            data.eventData.calendars.forEach((cal) => {
                let pomEvents = {"Meetings" : [], "Calls" : [], "Tasks" : [], "Events" : []};

                cal.Meetings.forEach((act) => {
                    pomEvents.Meetings.push(act);
                });

                cal.Calls.forEach((act) => {
                    pomEvents.Calls.push(act);
                });

                cal.Tasks.forEach((act) => {
                    pomEvents.Tasks.push(act);
                });

                self.calEvents.calendars[cal.id] = {id : cal.id, events: pomEvents};

            });

            setEvents(self,userID, {prefix: prefix},(data) => {
                _callback(data);
            });
        });
    }
    else{
        setEvents(self,userID, {prefix: prefix},(data) => {
            _callback(data);
        });
    }
}

export function setEvents(self,userID, attrs,_callback){
    // na widgetu sa sem dostane my_callendar misto user id netusim proc toto je jenom quickfix
    if (userID === "my_calendar"){
        userID = self.dataGet("conf/user/id")
    }

    if (userID === null){
        // nevim proc ale u libora se to zacyklilo na sdilenem kalendari
        // totozabrání zacyklení ale kalendář vyrendruje jednou navíc a zůstane loading
        // stačilo změnit nastavení sdíleného kalendáře a vše funguje (vybrat lidi a uložit)
        console.log("[ABORT] reload userID is null");
        return;
    }
    
    let usersCalendar = self.calEvents.calendars[userID];
    if (usersCalendar) {
        setEventsCalendar(self, attrs, usersCalendar, (data) => {
            if (_callback) {
                _callback(data)
            } else {
                self.calPlanReload()
            }
        });
    }
    else{
        self.calPlanReload() 
    }
}

export function setEventsCalendar(self, attrs, cal, _callback){
    let filters = self.dataGet(attrs.prefix);
    if (!(filters instanceof object)) {
        filters = Object.fromEntries(filters); // widgety to posilaji v mape...
    }
    let events = [];
    var eventsPom = null;

    if(attrs.widget){
        eventsPom = self.dataGet(attrs.prefix+"/eventData");
    }
    else{
        eventsPom = cal.events;
    }

    if(filters.showMeetings){
        eventsPom.Meetings.forEach((ev) => {
            if(!filters.showCompleted){
                if(ev.status != "Planned"){
                    return;
                }
            }

            if(filters.meetStates && filters.meetStates.size > 0){
                if(!filters.meetStates.includes(ev.status)){
                    return;
                }
            }
            let allDay = ev.all_day == "1" ? true: false;
            events.push(setEventStyle({id: ev.id,
                title: ev.name,
                description: ev.description,
                date: ev.date_start,
                start : ev.date_start,
                end: ev.date_end,
                act_type: "Meetings",
                status: ev.status,
                allDay: ev.allDay,
                orderLines: ev.orderLines,
                assigned_user_id: ev.assigned_user_id,
                assigned_user_name: ev.assigned_user_name,
                meetType: ev.meetType,
            }));
        });
    }

    if(filters.showCalls){
        eventsPom.Calls.forEach((ev) => {
            if(!filters.showCompleted){
                if(ev.status != "Planned"){
                    return;
                }
            }

            if(filters.callStates && filters.callStates.size > 0){
                if(!filters.callStates.includes(ev.status)){
                    return;
                }
            }
            let allDay = ev.all_day == "1" ? true: false;
            events.push(setEventStyle({id: ev.id, title: ev.name, description: ev.description, date: ev.date_start, start : ev.date_start, end: ev.date_end, act_type: "Calls", status: ev.status, allDay: ev.allDay}));
        });
    }

    if(filters.showTasks){
        
        let tasksLastDay = self.dataGet(attrs.prefix+"/setting/tasksLastDay");

        eventsPom.Tasks.forEach((ev) => {
                if(!filters.showCompleted){
                    if(ev.status == "Completed"){
                        return;
                    }
                }

                if(filters.taskStates && filters.taskStates.size > 0){
                    if(!filters.taskStates.includes(ev.status)){
                        return;
                    }
                }

                let dateStartPom = ev.date_start;
                if(tasksLastDay){
                    if(ev.date_start.substring(0, 10) != ev.date_end.substring(0, 10)){
                        let dateObj = moment(ev.date_end).subtract(30, "minutes");
                        dateStartPom = dateObj.format("YYYY-MM-DD HH:mm:ss");
                    }
                }
                let allDay = ev.all_day == "1" ? true: false;
            events.push(setEventStyle({id: ev.id, title: ev.name, description: ev.description, date: ev.date_start, start : ev.date_start, end: ev.date_end, act_type: "Tasks", status: ev.status, allDay: ev.allDay}));
        });
    }

    _callback(events);
}

export function setEventStyle(event){
    switch (event.act_type) {
        case "Meetings":
            //event.backgroundColor = "rgb(210, 229, 252)";
            switch (event.meetType) {
                case "montaz":
                    event.backgroundColor = "#3e9aa3";
                    event.eventBackgroundColor = "#3e9aa3"; // kvuli mesicnimu pohledu
                    event.borderColor = "#3e9aa3";
                    break;
                default:
                    event.backgroundColor = "#668DE5";
                    event.eventBackgroundColor = "#668DE5"; // kvuli mesicnimu pohledu
                    event.borderColor = "#668DE5";
                    break;
            }
            break;
        case "Calls":
            event.eventBackgroundColor = "#F5A26F"; // kvuli mesicnimu pohledu
            event.backgroundColor = "#FABB93";
            event.borderColor = "#FABB93";

            break;
        case "Tasks":
            event.eventBackgroundColor = "#31C136"; // kvuli mesicnimu pohledu
            event.backgroundColor = "#58C97E";
            event.borderColor = "#58C97E";
            break;
        default:
            break;
    }

    event.textColor = "#FFF";

    return event;
}
