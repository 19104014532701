import React from 'react'

export default function runCronNow(paramData){
    // definice funkce, ktera se pozdeji zavola z popupu po potvrzeni

    const generatePopupContent = (data) => {
        return (
            <div className={"acmPopupContent"}>
                <div className={"acmPopupContentWrapper"} style={{width: "100%"}}>
                    <div>
                        {this.translate(data.text)}
                    </div>
                    <div style={{paddingTop: "10px", fontWeight: "bold"}}>
                        {this.translate("LBL_CRON_OUTPUT")}:
                    </div>
                    <div style={{paddingTop: "10px"}}>
                        <div
                            dangerouslySetInnerHTML={{ __html: data.data }}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const callCronRest = () => this.rest.fetchData("runCronNow", "POST", paramData).then((data) =>{
        this.unLoad();
        this.alert(generatePopupContent(data));
    }).catch((data) =>{
        this.unLoad();
        this.error(generatePopupContent(data));
    });
    // konec definice funkce, ktera se pozdeji zavola z popupu po potvrzeni

    this.popup(this.translate("LBL_CRON_REALLY_RUN_CRON"), {
        header: this.translate("LBL_CRON_INFO"),
        buttons: [
            {
                label: (<><div className='icon-detailCancel buttonIcon '> </div> {this.translate("LBL_CANCEL_ACTION")}</>),
                callback: () => {
                    this.popupHide();
                },
            },
            {
                label: (<><div className='icon-detailConfrim buttonIcon'> </div> {this.translate("LBL_CONFIRM")}</>),
                callback: () => {
                    this.popupHide();
                    this.load();
                    callCronRest();
                },
            },

        ],
    })
}
