export default function parseCSVToArray(data, delimiter = ';', limit = null) {
    delimiter = (delimiter || ';');

    const re = new RegExp((
        "(" + delimiter + "|\\r?\\n|\\r|^)(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|([^" + delimiter + "\\r\\n]*))"
    ), 'gi');
    const result = [[]]
    let matches
    while ((matches = re.exec(data))) {
        if (matches[1].length && matches[1] !== delimiter) {
            if (limit && result.length >= limit) {
                break;
            }
            result.push([]);
        }
        result[result.length - 1].push(
            matches[2] !== undefined ? matches[2].replace(/""/g, '"') : matches[3]
        )
    }
    return result
}
