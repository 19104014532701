export default function translate(key, module = null, selectedValue = '', preventArrayReturn = true) {
    let returnString;

    if (module == null) {
        if (this.dataGet('rightPanel/show') === true) {
            module = this.dataGet('rightPanel/data/detail/module')
        } else {
            module = this.getModuleName()
        }
    }

    switch (true) {
        case module && (returnString = this.moduleTranslate?.[module]?.[key]) != null: break;
        case (returnString = this.app_strings?.[key]) != null: break;
        default: returnString = key; break;
    }

    if (selectedValue) {
        returnString = returnString[selectedValue] ?? selectedValue;
    }

    if (preventArrayReturn && returnString !== null && typeof returnString === 'object') {
        returnString = selectedValue || key;
    }

    return returnString;
}
