export default function mapFromFirstRow(prefix, row, fields, module) {
    row.forEach((column, key) => {
        const fieldName = fields.find((field) => {
            return this.translate(field.vname, module) === column || field.name === column;
        });
        if (fieldName) {
            this.dsAdd('set', prefix + '/' + key, {value: fieldName.name, type: fieldName.type, tab: 0, label: this.translate(fieldName.vname, module)});
        }
    })
}
