export default function translate(key, module, variables, removeUnused = true) {
    let translation = this.translate(key, module);

    let escapedArray = [];

    variables.forEachObject((replaceTo, replaceFrom) => {
        if (/^[a-zA-Z0-9]+$/i.test(replaceFrom)) { return; }
        translation = translation.replaceAll(`{#${replaceFrom}#}`, replaceTo);
    });

    return removeUnused ?
        translation.replace(/((?=^|[^\\]){#[\w]+#})|(\\)({#[\w]+#})/g, '$3') : // preg_replace('/((?<=^|[^\\]){#[\w]*#})|(\\)({#[\w]*#})/U', '$3', translation) : // Prazdne NEBO s lomitkem pred
        translation.replace(/(\\)({#[\w]+#})/g, '$2') ; // preg_replace('/(\\)({#[\w]*#})/U', '$2', translation); // s lomitkem pred

}
