import React, {PureComponent} from 'react'
import PropTypes from "prop-types"
import DropDownButton from './DropDownButton'

/**
 *
 * Ukazkova data - Jak dat na firmach ares a google do jednoho dropdownu + vice zp. zobrazeni
 *
 const buttons = [
      {
        text:'ares',  // text na tlačítku
        onClick:this.ares2,  // funkce, která s spustí při kliknutí - musí být tam kde renderujeme DropDown,
        icon:'ares', // icona - vždy v levo
        title:'ares', // text při najetí
        customClass:'', // speciální classy pro tlačítko
      },
      {
        text:'google',
        onClick:this.SearchOnGoogle,
        icon:'google',
        title:'google',
        customClass:'',
      },
      {
        text:'google2',
        onClick:this.SearchOnGoogle,
        icon:'google2',
        title:'google2',
        customClass:'',
      },
      {
        text:'',
        onClick:this.ares2,
        icon:'ares',
        title:'ares2',
        customClass:'',
      },
    ]

 <DropDown
   buttons={buttons}
   icon="play"
   openIcon="playOpen"
   hoverable={true}
   customClass='' // classa pro dropDown tlacitko
   title:'',
   defaultOpen={true} // Nevime jeste vyuziti, ale treba se bude hodit
 />
 */

export default class DropDown extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      open: this.props?.defaultOpen ?? false,
      id : `${this.props.title}-${+ new Date()}`
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = e => {
    if (this.state.open && e.target.id !== this.state.id) {
      this.setState({open: false})
    }
  }

  toggleDropDown() {
    this.setState({open: !this.state.open})
  }

  render() {
    return (
      <a className={`dropDownComponent
      icon-${this.state.open ? this.props.openIcon??this.props.icon??"playOpen" : this.props.icon??"play"} 
      ${this.props?.hoverable === false ? '' : ' hoverable'}
      ${this.props?.customClass}
      ${this.state.open ? "grayHoverClass" : ""}`}
         title={this.props.title??""}
         id={this.state.id}
         onClick={() => this.toggleDropDown()}>
        <div className="dropdown-content-wrapper">
          <ul className={`dropdown-content ${this.state.open ? 'open' : ''}`}>
            {this.props.buttons.map((button, key) =>
              <DropDownButton
                  key={key}
                  customClass={button.customClass}
                  onClick={button.onClick}
                  title={button.title}
                  icon={button.icon}
                  text={button.text}
              />
            )}
          </ul>
        </div>
      </a>
    )
  }
}

DropDown.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    text:PropTypes.string.isRequired,
    onClick:PropTypes.func.isRequired,
    icon:PropTypes.string,
    title:PropTypes.string,
    customClass: PropTypes.string,
  })).isRequired,
  icon:PropTypes.string,
  openIcon:PropTypes.string,
  hoverable:PropTypes.bool.isRequired,
  customClass:PropTypes.string,
  title:PropTypes.string,
  defaultOpen:PropTypes.bool,
};
