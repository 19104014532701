import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import EmailInputField from "./EmailInputField";


export default class Email extends PureComponent{
    constructor() {
        super();
    }

    componentDidMount() {
        const count = this.props.data.def.get("emails").size;
        if (!count) {
            this.addEmail("1");
        }
    }

    addEmail = (primary_address = "0") => {
        sAction.dataAdd(this.props.way + "/def/emails", {
            email_address: "",
            opt_out: "0",
            invalid_email: "0",
            primary_address,
        });
    };

    render() {
        const data = this.props.data;
        const emails = data.def.get("emails");
        const astyle = !emails.size && data.def.get("required") ? {color: "red"} : {};
        let emailFields = [];
        emails.forEach((email, i) => {
            let key = email.get("email_address_id");
            if (!key) key = email.get("email_address");
            emailFields.push(
                <EmailInputField
                    prefix={this.props.prefix}
                    way={this.props.way}
                    data={this.props.data}
                    newRecord={this.props.newRecord}
                    rowWay={this.props.rowWay}
                    index={this.props.index}
                    module={this.props.module}
                    email={email}
                    emailKey={i}
                    key={i}
                />
            );
        });

        const primary = sAction.translate("LBL_EMAIL_PRIMARY");
        const opt_out = sAction.translate("LBL_EMAIL_OPT_OUT", "Emails");
        const invalid = sAction.translate("LBL_EMAIL_INVALID");
        const primary_letter = primary[0];
        const opt_out_letter = opt_out[0];
        const invalid_letter = invalid[0];

        return(
            <React.Fragment>
                {emails.size > 0 &&(
                    <div className="EmailInputFieldRow">
                        <div className="inputEditContainer">
                        </div>

                        <span title={primary}>{primary_letter}</span>
                        <span title={opt_out}>{opt_out_letter}</span>
                        <span title={invalid}>{invalid_letter}</span>
                    </div>
                )}
                
                {emailFields}
                {/*Přidat e-mail*/}
                <a
                    onKeyDown={e => this.onKeyDown(e, "last")}
                    onClick={() => this.addEmail()}
                    tabIndex="0"
                    style={astyle}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            this.addEmail();
                        }
                    }}
                >
                    + <span className="icon-Emails" />
                    {" "+sAction.translate("LNK_NEW_EMAIL", "Contacts")}
                </a>
            </React.Fragment>
        );
    }
}
