import {calPlanReload} from "../../exportConfig"

export default function saveCalSettings(state, callBack) {
  var self = this

  this.load()
  this.rest.post("saveCalSettings", state, function(ret) {
    if (ret.status) {
      callBack()
      self.calPlanReload()
      // this.unLoad(); soucasti calPlanReload()
    } else {
      this.unLoad()
      alert(ret.errorMessage.text)
    }
  })
}
