export default function updateDynamicEnum(params,callback){
    params["lang"] = this.dataGet("conf/language");
    this.rest.post("updateDynamicEnumList",params,returnData => {
        if (returnData.status === true) {
            callback(returnData.message);
        } else {
            this.unLoad();
            if (returnData.errorMessage?.text) {
                this.error(this.translate(returnData.errorMessage.text))
            }
        }
    })
}
