import React, {PureComponent} from 'react'
import PropTypes from "prop-types"

export default class DropDownButton extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {

    return (
        <li key={this.props.key} className={this.props.customClass}>
          <span onClick={this.props.onClick} title={this.props.title} className={'dropdown-content-row'}>
              <i className={"icon-" + (this.props.icon ?? 'play' + " " + this.props.customClass) + " " + this.props.text??''}/>
            <span>
              {this.props.text}
            </span>
          </span>
        </li>
    )
  }
}

DropDownButton.propTypes = {
  customClass:PropTypes.string,
  onClick:PropTypes.func.isRequired,
  title:PropTypes.string,
  icon:PropTypes.string.isRequired,
  text:PropTypes.string.isRequired,
  key:PropTypes.number.isRequired,
};
