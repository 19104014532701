import React from "react";

import MaterialDefault from './MaterialDefault'
import InputText from "./InputText";
import  sAction  from "sAction";
import Autocomplete from "./Autocomplete";

export default class Relate extends MaterialDefault {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      state: "fine",
      value: props.data.value,
      id: null,
      resultOpen: false,
      searchText: "",
    };
    this.input = React.createRef();
    this.autocomplete = React.createRef();
  }

  callAutocomplete = (keyCode) =>{
    if(this.autocomplete?.current){
      this.autocomplete.current.keyUpHandler(keyCode);
    }
  }

  keyUp(event) {
    const keyCode = event.keyCode;
    switch (keyCode){
      case 40:
      case 38:
        this.callAutocomplete(keyCode);
        return;
      break;
      case 27:
        this.cancel();
        return;
      break;
      case 13:
        if(event.ctrlKey){
          return;
        }
        if (this.state.resultOpen == true) {
          if(this.autocomplete?.current){
            this.autocomplete.current.keyUpHandler(keyCode);
          }
        } else {
          //this.save();
        }
        return;
      break;
    }
    if(!this.input?.current?.value){
      this.setState({resultOpen: false});
    } else {
      this.setState({searchText: this.input.current.value, resultOpen: true});
    }
  }

  selectItem(item, doUpdate = true,cancelEdit = true) {
    this.setState({
      id:item.id,
      name:item.name,
    })
    this.props.callback(item);
    if(item.name) {
      this.input.current.value = item.name;
    }
    this.cancel();
  }

  focus() {
    if(!this.input?.current?.value){
      this.setState({resultOpen: false});
    } else {
      this.setState({searchText: this.input.current.value, resultOpen: true});
    }
  }

  openPopupList(selectedActive = false) {
    var self = this;
    const data = {
      module: this.props.module,
      selectedActive: selectedActive,
      defaultFilter: this.props.defaultFilter
    };

    sAction.openRelatePopup(data, returnData => {
      self.selectItem(returnData);
      sAction.popupHide();
    });
  }
  chechChange(){
    if(this.state.value !== this.input.current.value){
        if (this.props.updateField == true) {
            this.input.current.value = '';
        } else {
            this.props.callback({name : this.input.current.value});
        }
    }
    this.cancel();
  }
  onBlur(e) {
    const relTarget = e.relatedTarget;

    if (relTarget == null) {
      this.chechChange();
    }
    else if (!(e.relatedTarget.className == "quickSearchResultLine")){
      this.chechChange();
    }
  }

  cancel(){
    if (this.searchInterval) {
      clearInterval(this.searchInterval);
    }
    this.setState({
      resultOpen: false
    });
  }

  storeRelate = (item) =>{
    if(item){
      this.selectItem(item);
    } else {
      this.setState({resultOpen: false}); // pokud neni vybrana hodnota z naseptavace zavre se okno a uzivatel muze pokracovat v jinem fieldu
    }
  }

  componentDidMount(){
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    var containerClassName = this.props.containerClassName ? this.props.containerClassName : null;
    var containerClass = "inputEditContainer " + containerClassName;
    let keyAttr = {}
    let selectedActive = false

    var value = data.value;
    if (newRecord && (value === "" || value === undefined || value === null)) {
      value = "";
    }

    if(this.props.name || this.props.name === ""){
      value = this.props.name
      selectedActive = true
      keyAttr = {key: value}
    }

    //const searchResult = this.getSearchResult();

    const containerClassField = "inputContainer relateField";

    if(!containerClass || (containerClass && !containerClass?.includes("relateField"))){
      containerClass += " relateField"
    }

    var btnComp = [];
    //buttons={[{className: "detailInfoIcon",  onClick: this.prodInfo(data.id)}]}
    if(this.props.buttons != undefined){
      this.props.buttons.forEach(btn => {
        btnComp.push(<div
          key={btn.id}
          onClick={() => btn.onClick(btn.params)}
          className="inputEditButton"
        >
          <div className={btn.className} />
        </div>)
      });
    }
    return (
      <div className={containerClass} data-fieldname={data.name} style={this.props.containerStyle ? this.props.containerStyle : null}>
        {/*searchResult*/}
        <div className={containerClassField}>
          <InputText
            {...keyAttr}
            onKeyDown={e => this.onKeyDown(e)}
            onKeyUp={event => this.keyUp(event)}
            onFocus={() => this.focus()}
            onBlur={e => this.onBlur(e)}
            myRef={this.input}
            id={data.name}
            defaultValue={value}
            label={this.props.data.label}
            InputProps={this.props.InputProps}
            error={this.props.error}
            autoFocus={this.props.autoFocus != undefined ? this.props.autoFocus : false}
            className={this.props.inputClassName ? this.props.inputClassName : null}
          />
          {this.state.resultOpen ? <Autocomplete
              data={data}
              resultOpen={this.state.resultOpen}
              searchText={this.state.searchText}
              searchMethod={"formRelate"}
              autocompleteReturnItem={this.storeRelate}
              newRecord={this.newRecord}
              ref={this.autocomplete}
              module={this.props.module}
          /> : null}
        </div>
        {!newRecord && (
          <div className="buttonContainer">
            <div
              onClick={() => this.openPopupList(selectedActive)}
              className="inputEditButton openPopupButton"
            >
              {!this.props.hideSearchIcon && (<div className={"icon-search relateSearchIcon"} />)}
            </div>
            {btnComp}
          </div>
        )}
      </div>
    );
  }
}
