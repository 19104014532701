import React from "react";
import InputField from "../inputFieldClass";
import InputText from "../../formElements/InputText";
import sAction from "sAction";

import Autocomplete from "../../formElements/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";

export default class DefaultRelate extends InputField {
  constructor(props) {
    super(props);
    this.state = {
      state: "fine",
      resultOpen: false,
      searchText: "",
      id: props?.data?.def.get("id_value"),
      value: props.data?.value,
      keyCode: null,
    };
    this.input = React.createRef();
    this.autocomplete = React.createRef();
  }

  callAutocomplete = (keyCode) =>{
    if(this.autocomplete?.current){
      this.autocomplete.current.keyUpHandler(keyCode);
    }
  }

  keyUp(event) {
    const keyCode = event.keyCode;
    switch (keyCode) {
      case 8:   //backspace
      case 46:  // delete
        this.checkEmptyValue(false, event)
      break;
      case 27:  // esc
          if(this.state.resultOpen === true){
            this.setState({resultOpen: false, keyCode: keyCode})
            return;
          } else {
            this.cancel();
          }
      break;
      case 38:  //sipka nahoru
      case 40:  //sipka dolu
          this.callAutocomplete(keyCode);
          this.setState({keyCode})
        return;
      break;
      case 13:  //enter
        this.enterHandler(keyCode);
        return;
      break;
      case 9:   //tab
        if (this.state.id !== "" || this.state.value === this.input?.current?.value) {
          this.input.current.value = this.state.value;
          this.save();
          super.onKeyDown(event, null, false);
        } else {
          this.setState({keyCode: keyCode});
        }
        return;
      break;
    } // end of switch

    if (!this.input || !this.input.current || !this.input.current.value) {
      this.setState({resultOpen: false});
    } else {
      this.setState({searchText: this.input.current.value, resultOpen: true,keyCode: keyCode});
    }
  }

  enterHandler = (keyCode) => {
    if(this.state.resultOpen){
      this.callAutocomplete(keyCode);
    } else {
      if ((this.input?.current?.value === this.state.value) || (this.state.id !== "" && this.state.value === "")) { // druha podminka je pro pripad kdy kliknu na relate z hned na enter, aby se pole uzavrelo
        this.save();
      } else {
        this.setState({keyCode: keyCode});
      }
    }
  }

  checkEmptyValue(loseFocus, e) {
    if(e && e.relatedTarget && e.relatedTarget.querySelector(".icon-detailSearch")){
      loseFocus = false;
    }
    if(!this.input.current.value){
      this.selectItem({ id: "", value: "" }, true, loseFocus)
    }
  }

  selectItem(item, doUpdate = true, cancelEdit = true) {
    const data = {
      way: this.props.way,
      name: this.props.data.def.get("id_name"),
      fieldName: this.props.data.name,
      vname: this.props.data.def.get("vname"),
      type: "relate",
      value: item,
      cancelEdit,
    };
    this.setState({
      id: item.id,
      value: item.name,
      resultOpen: false,
    });
    this.saveField(data, doUpdate);
    if (this.props.onSelect !== undefined) {
      this.props.onSelect();
    }
  }

  openPopupList() {
    var self = this;
    const data = {
      module: this.props.data.def.get("module"),
      selectedActive: false,
    };
    const defaultFilter = this.props.data.def.get("defaultFilter");
    if (defaultFilter) {
      data.defaultFilter = defaultFilter.toJS();
    }
    sAction.openRelatePopup(data, (returnData) => {
      self.selectItem(returnData);
      if (!returnData.popupHidden) {
        sAction.popupHide();
      }
    });
  }
  newRecord = () => {
    const module = this.props.data.def.get("module");
    sAction.popupDetail({
      module,
      record: "",
      saveCallback: (ret) => {
        const item = {
          id: ret.record,
          name: ret.name,
        };
        const data = {
          way: this.props.way,
          name: this.props.data.def.get("id_name"),
          fieldName: this.props.data.name,
          type: "relate",
          value: item,
          cancelEdit: true,
        };
        this.setState({
          id: item.id,
          value: item.name,
        });
        this.saveField(data, true);
        sAction.unLoad();
      },
      exitCallback: (data) => {},
    });
  }

  onBlur(e) {
    const relTarget = e.relatedTarget;
    if (relTarget == null) {
      this.cancel();
    } else {
      var parent = relTarget.closest(
        "div[data-fieldname='" + this.props.data.name + "']"
      );
      if (parent == null) {
        this.cancel();
      }
    }
  }
  deleteValue = () => {
    this.selectItem({ id: "", name: "" });
  };

  storeRelate = (item) =>{
    if(item) {
      this.selectItem(item, true, false);
      this.setState({resultOpen: false});
      this.input.current.value = item.name;
      if(item.selectMethod === "click"){
        this.save();
      }
      return;
    }
    if((this.input.current.value === this.state.value) && this.state.value){
      this.save();
      return;
    }
    this.setState({keyCode: 13}); //KEYCODE ENTERU
  }

  onKeyDown(e, type = null, save = true) {
    if(e.keyCode === 9){
      e.preventDefault();
      return;
    }
    super.onKeyDown(e, type, save);
  }

  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    let containerClass = "inputEditContainer"
    const keyCode = this.state.keyCode;
    let error = false;
    if((keyCode == 13 || keyCode === 9) && this.input.current.value !== this.state.value){
      error = sAction.translate("LBL_SELECT_ITEM");
    }

    if(!containerClass || (containerClass && !containerClass?.includes("relateField"))){
      containerClass += " relateField"
    }

    let value = data.value;
    if (newRecord && (value === "" || value === undefined || value === null)) {
      value = "";
    }
    else{
      value = sAction.decodeHTMLEntities(value);
    }

    const containerClassField = "inputContainer relateField";


    return (
      <div className={containerClass} data-fieldname={data.name}>
        <div className={containerClassField}>
          <InputText
            onKeyDown={(e) => this.onKeyDown(e)}
            autoFocus={true}
            onKeyUp={(event) => this.keyUp(event)}
            onBlur={(e) => this.checkEmptyValue(true, e)}
            myRef={this.input}
            id={data.name}
            defaultValue={value}
            autoComplete="off"
          />
          {this.state.resultOpen ? <Autocomplete
              data={data}
              resultOpen={this.state.resultOpen}
              searchText={this.state.searchText}
              searchMethod={"relate"}
              autocompleteReturnItem={this.storeRelate}
              addButton={true}
              newRecord={this.newRecord}
              defaultFilter={data.def.get("defaultFilter")}
              selectedId={data.def.get("id_value")}
              module={data.def.get("module")}
              ref={this.autocomplete}
          /> : null}
        </div>
        {!newRecord && (
          <div className="buttonContainer">
            {!!error &&
                (
                    <div
                        tabIndex="-1"
                        className="warningMessage inputEditButton"
                    >
                      <Tooltip title={error} arrow open>
                        <div className="icon-warning" />
                      </Tooltip>
                    </div>

                )}
            <div
              tabIndex="1"
              onClick={() => this.openPopupList()}
              className="inputEditButton"
            >
              <div className={"icon-detailSearch"} />
            </div>
            <div
              tabIndex="2"
              onClick={() => this.deleteValue()}
              className=" inputEditButton"
            >
              <div className={"icon-detailCancel"} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
