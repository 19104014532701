import React from 'react'
import PureComponent from '../pure'

import CalHeader from './calHeader'
import CalFilters from './calFilters'
import CalBody from './calBody'

import AcmDate from '../formElements/AcmDate'
import sAction from "sAction"
import PropTypes from 'prop-types'

import "@fullcalendar/common/main.css"
import "@fullcalendar/daygrid/main.css"
import "@fullcalendar/timegrid/main.css"

class CalPageContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      cssSelector: "acmFullCalendar",
      openPicker: false,
      fullScreen: false
    }
  }

  /**TODO snizit pocet prenacitani:*/
  // shouldComponentUpdate(nextProps, nextState){
  //   return true
  // }

  toggleFullScreen = () => {
    this.setState({
      fullScreen: !this.state.fullScreen
    })
    
    sAction.calPlanReload('soft')
  }

  render() {
    const data = this.props.data
    const sharedView = data.toJS().sharedView
    const usersArr = data.setting.get('sharedIds')
    const usersNamesArr = data.setting.get('users')
    let classBoard = " calFiltWidth"
    let curUserID = sAction.dataGet('conf/user/id')
    let curUserName = usersNamesArr ? usersNamesArr[curUserID] : ''
    let calBody = []
    if (usersArr && sharedView) {
      if (usersArr.includes(curUserID)) {
        calBody.push(
          <CalBody key={'calBody' + curUserID}
            data={data}
            viewsNumber={usersArr?.length}
            classBoard={classBoard}
            cssSelector={this.state.cssSelector}
            userName={curUserName}
            language={this.props.language}
            userID={curUserID}
            sharedView={sharedView}
          />
        )
      }
      usersArr.forEach((userID) => {
        if (userID !== curUserID) {
          calBody.push(
            <CalBody key={'calBody' + userID}
              data={data}
              viewsNumber={usersArr?.length}
              classBoard={classBoard}
              cssSelector={this.state.cssSelector}
              userName={usersNamesArr[userID]}
              language={this.props.language}
              sharedView={sharedView}
              userID={userID} />
          )
        }
      })
    } else {
      calBody.push(
        <CalBody key={'calBody' + curUserID}
          data={data}
          viewsNumber={1}
          classBoard={classBoard}
          cssSelector={this.state.cssSelector}
          language={this.props.language}
          userName={curUserName}
          sharedView={sharedView}
          userID={curUserID} />
      )
    }
    var picker = <AcmDate autoOpen={true} value={new Date()} onChange={(d) => this.DatePickerHandle(d)} />

    if (this.state.openPicker === false) {
      picker = null
    }

    return (
      <div>
        <div className={"calPageContainer detailCard" + (this.state.fullScreen && " fullscreenCal")}>
          <CalHeader 
            calCssSelector={"." + this.state.cssSelector} 
            fncGoToDate={this.callGoToDate}
            sharedView={sharedView} 
            title={data.title} 
            setting={data.setting} 
            toggleFullScreen={this.toggleFullScreen} />

          <CalFilters data={data.filters} />

          <div className={'calendarsContainer'}>
            {calBody}
          </div>

        </div>
      </div>)
  }
}

export default CalPageContainer

CalPageContainer.propTypes = {
  data: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
}