// import $ from 'jquery';
export default function calGetAdditionalInfo(ev, event){
    this.rest.post("getAdditionalInfo/"+event.act_type+"/"+event.id, {}, function(data){
        
        // TODO: vyresit jinak nez pres jquery jestli se bude pouzivat

        // var box = $("<div>", {id: 'adicon_box', style: 'top:'+ev.pageY+'px; left: '+(ev.pageX - 300)+'px;'}); //CSS
            
        // var header = $("<div>", {class: "calAdiconHeader"});//CSS
        // header.append($("<span>", { class: 'calAdiconBoxTitle', text: data.title}));
               
        // var iconHolder = $("<div>", {class: "calAdicolIconHolder"})

        // var icon_detail = $("<div>", {class: "icon-calDetailIcon adicon"});
        // icon_detail.click(function(e){
        //    window.open("#detail/"+event.act_type+"/"+event.id,'_blank');;
        // });
        // iconHolder.append(icon_detail);

        // var icon_close = $("<div>", {class: "icon-calCloseIcon adicon"});
        // icon_close.click(function(e){
        //     $("#adicon_box").remove();
        // });
        // iconHolder.append(icon_close);

        // header.append(iconHolder);

        // box.append(header);

        // var container = $("<div>", {class: "calAdiconContainer", html: data.htmlBody});


        // box.append(container);
        
        // //box.resizable();
        // //box.draggable();    
        // $("body").append(box);
    });
}
