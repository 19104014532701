export default function calPlanReload(target = 'calendar'){
  this.dsClear();
  //TODO nejak lepe to vymyslet, protoze to prenacita i calPageContainer
  if (target === 'calendar' || target === 'all') {
    this.dsAdd("set", 'calendar/reload', true);
  }
  if (target === 'external' || target === 'all') {
    this.dsAdd("set", 'calendar/filters/reloadExternal', true);
  }
  if (target === 'soft') {
    // neni idealni ale staci kdyz potrebuju prerenderovat bez znovu nacitani dat
    let actualCallView = this.dataGet('calendar/calViewType')
    this.dataSet('calendar/calViewType', 'dayGridMonth');
    this.dataSet('calendar/calViewType', actualCallView);
  }
  this.dsProcess();
}
