
import detailDefault from "../../detailDefault";
export default class detailOpportunities extends detailDefault{
    load(sAction, data) {
        sAction.dsClear();
        const sales_stage = sAction.dataGet(data.prefix+"/fields/sales_stage/value");
        if( sales_stage == "Closed Lost" ||
            sales_stage == "Closed canceled client" ||
            sales_stage == "Closed canceled me"
            ){
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", true);
                sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);
            }
            else{
                if(sales_stage == "Closed Won"){
                    sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                    //$("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "hidden");   
                    //sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/visible", false);
                }
                else{
                    sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", true);
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "none");
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "none");  
                }
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", false);
            }

            if(sAction.getModuleName() == "Contacts" && data.prefix != "view"){
                const accData = sAction.dataGet("view/fields/account_name");
                const name = accData.get("value");
                const id = accData.getIn(["def","id_value"])
                if(id){
                sAction.dsAdd("set", data.prefix+"/fields/account_name/value", name);
                sAction.dsAdd("set", data.prefix+"/fields/account_name/def/id_value", id);
                sAction.dsAdd("set", data.prefix+"/changes/fields/account_id", id);
                }
            }
            sAction.dsProcess();
    }

    update(sAction,data) {
        if(data.field == "account_id"){
            const name = sAction.dataGet(data.prefix+"/fields/name/value");
            if(name == null || name == ""){
                const nameAcc = sAction.dataGet(data.prefix+"/fields/account_name/value");
                sAction.dsClear();
                sAction.dsAdd("set",data.prefix+"/fields/name/value",nameAcc);
                sAction.dsAdd("set",data.prefix+"/changes/fields/name",nameAcc);
                sAction.dsProcess();
            }
        }
        else if(data.field == "sales_stage"){
            if( data.value == "Closed Lost" ||
                data.value == "Closed canceled client" ||
                data.value == "Closed canceled me"
            ){
                //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
                //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "flex");  
                //$("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "visible");

                sAction.dsClear();
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", true);
                sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);

                
                sAction.dsProcess();
            }
            else{
                sAction.dsClear();
                if(data.value == "Closed Won"){
                    sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "flex");
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "flex");   
                    //$("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "hidden");    
                }
                else{
                    sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", true);
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "none");
                    //$("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "none");    
                    
                }

                
               
                sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", false);
                sAction.dsProcess();
                
            }
        }
        
    }

}
