export default function openDynamicEnum(way, data, callback) {
    const enumId = data.def.get('options');
    const parent = data.def.getIn(['relData', 'parent']);
    const fields = this.dataGet(way+'/..');
    const currentLanguage = this.dataGet('conf/language');
    const enums = {};
    fields.forEach((field) => {
        if (field.def.get('type') == 'AcmDynamicEnum') {
            const id = field.def.get('options');
            const value = field.value;
            enums[id] = value;
        }
    });
    const params = {
        enum: enumId,
        parentValue: enums[parent],
        enums,
    };

    this.rest.post('dynamicEnumList', params, (returnData) => {
        returnData['lines'] = returnData['lines'].sort((a, b) =>
            JSON.parse(a.language_json)[currentLanguage].localeCompare(JSON.parse(b.language_json)[currentLanguage]));
        callback(returnData);
    });
}
