import sAction from "../index";
import requiredFieldsCheck from 'sAction/DetailView/requiredFieldsCheck';

export default function saveRecordBase(paramData, callback) {
  this.doRoute = true;
  const module = paramData.module;
  var fields = this.dataGet(paramData.prefix + "/fields");
  var wrongFields = [];
  const imagesToDelete = this.dataGet("view/imageActions/deleteOnSave");

  // promazani zmen v local storage
  sAction.removeChangesFromLS(module,sAction.dataGet('conf/user/id'))

  if(module == "Meetings" || module == "Calls"){
    this.dataSet(paramData.prefix + "/changes/invitees", this.dataGet(paramData.prefix + "/customData/invitees/relatedInv"));
    this.dataSet(paramData.prefix + "/changes/inviteesBackup", this.dataGet(paramData.prefix + "/customData/inviteesBackup/relatedInv"));
  }
  // _______________________________________________ONLY QUOTES
  else if (module == "Quotes") {
    //handeling product lines on Quotes
    let quoteData = this.dataGet(paramData.prefix + "/customData");
    var prodData = quoteData.get("productData").toJS();
    const sums = this.calcSums(paramData.prefix + "/customData/productData", {
      type: "total",
      data: prodData,
      cenotvorba: prodData.cenotvorba,
    });
    const duplicateId = quoteData.duplicateId;
    const type = quoteData.type;
    var customData = { productData: prodData,  total_tax : sums.total_tax, total_discount : sums.total_discount, total: sums.total, duplicateId, type };
    this.dataSet(paramData.prefix + "/changes/customData", customData);
  }
  else if(module == "Opportunities"){
    this.dataSet(paramData.prefix + "/changes/customData/productData", this.dataGet(paramData.prefix + "/customData/productData"));
  }
  // _______________________________________________ONLY QUOTES END
    let sendToCrm = true;
    // _______________________________________________CUSTOM LINES
    let customLinesData = this.dataGet(paramData.prefix + '/customData');
    if (customLinesData && customLinesData.get && customLinesData.get('customLines') && customLinesData.get('customLines').get('lines')) {
      // CONVERT TO CHANGES
      this.dataSet(paramData.prefix + "/changes/customData/customLines", customLinesData.get('customLines').get('lines'));
      // CHECK IF REQUIRED
      let requiredFields = [];
      // Ziska povinna pole
      customLinesData.get('customLines').get('def').get('fields').toJS().forEachObject((field, key) => {
        if (customLinesData.get('customLines').get('moduleData').get(field).get('required') === true) {
          requiredFields.push(field);
        }
      });
      // Zjisti jeslti nejake povinne pole je prazdne
      if (Array.isArray(requiredFields) && requiredFields.length) {
        customLinesData.get('customLines').get('lines').toJS().forEachObject((line, key) => {
          requiredFields.forEach((field) => {
            if (!line[field] && line['deleted'] !== 1 && line['deleted'] !== '1') {
              this.dsClear();
              this.dsAdd('set', paramData.prefix + '/customData/customLines/showError', true);
              this.dsProcess();
              sendToCrm = false;
            }
          });
        });
      }
    }
    // _______________________________________________CUSTOM LINES END

    let boundModules = this.dataGet(paramData.prefix + '/boundModules');
    if (boundModules && !paramData.id) {
        this.dsClear();
        this.dsAdd("set",paramData.prefix + '/changes/customData/boundModules', {});
        boundModules.toJS().forEachObject((module, index) => {
            let way = paramData.prefix + '/boundModules/' + index;
            /**************** PROJIT KAZDY MODUL JESTLI NEMA PRAZDNE REQUIRED POLE */
            if (module.customData.checked === true) {
                if (!this.requiredFieldsCheck(way)) {
                    sendToCrm = false;
                }
                // Prida do changes data o modulu
                if (sendToCrm) {
                    const emails = this.customFieldEmail(way);
                    if (emails) {
                        module.changes.fields.email1 = emails;
                    }

                    this.dsAdd("set",paramData.prefix + '/changes/customData/boundModules/' + module.module, module.changes);
                }
            }
            /***********************************************************************/
        });
        this.dsProcess();
    }

    var editFields = this.dataGet(paramData.prefix + "/changes");


  const emails = this.customFieldEmail(paramData.prefix);
  if (emails) {
    editFields = editFields.setIn(["fields", "email1"], emails);
  }
    if (paramData.canSave === true) {
      if (this.custom("beforeSave", paramData)) {
          // Pokud je nejake required pole prazdne, hodi error a skonci
          if (!this.requiredFieldsCheckMsg(paramData.prefix) || !sendToCrm) {
              return;
          }

          const module = paramData.module;
          var src = module+"/";
          var id = paramData.id;
          if (id !== undefined && id !== null) {
            src += id
          }
          this.load();
          var self = this;

          if (module == 'Users' && id && id == this.dataGet('conf/user/id') && editFields.get('fields')) {
            editFields.get('fields').toJS().forEachObject((value, key) => {
              if (key == 'records_per_page') {
                this.dsClear();
                this.dsAdd("set",'conf/user/records_per_page', value);
                this.dsProcess();
              }
            });
          }
          this.rest.post("set/" + src, editFields, msg => {
            var data = {
              state: true,
              data: msg
            };
            if(module == "Quotes"){
              data["cancelPrevisouRequest"] = false
              self.rest.post("recalcTopRecent", {}, function(resp){
              },true);
            }
            callback(data);
          });

          if (imagesToDelete) {
            this.rest.delete("deleteImages/", imagesToDelete.toJS(), false, false)
          }
      }
    } else {
      const data = {
        state: false,
        msg: "Záznam momentálně nelze uložit"
      };
      callback(data);
    }
  }
