import React, {PureComponent} from "react";
import sAction from "sAction";
import PropTypes from "prop-types";

export default class FilterButtons extends PureComponent{
    render(){
        let buttons = [];
        const buttonDefs = this.props.buttonsDefs;
        buttonDefs?.forEachObject((buttonDef, key) => {
            buttons.push(
                <div
                    onClick={buttonDef.function
                    }
                    className="filterButton"
                    key={"filter_functions_" + key}
                >
                    {sAction.translate(buttonDef.label)}
                </div>
            );
        });
        return buttons;
    }
}

FilterButtons.propTypes = {
    buttonsDefs: PropTypes.arrayOf(
        PropTypes.shape({
            function: PropTypes.func.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired
};


