import React, { Component } from 'react'
import PureComponent from '../../pure'
import  sAction  from "sAction";
import TextField from "@material-ui/core/TextField";
import Relate from "../../formElements/Relate"
import CheckBox from "../../formElements/CheckBox"
import Select from '../../formElements/Select'
import confProd from "./productLineConfig";
import Currency_id from './../../detailViewFields/Currency_id/Currency_id';
import {Draggable} from "react-beautiful-dnd";
import Enum from "../../listViewFields/Enum";
import Image from "./Image";
import CustomTooltip from "ROOT/src/components/customTooltip/CustomTooltip";
import Pricing from "ROOT/src/components/detailView/Quotes/Pricing";
import {Map} from "immutable";
import ReactDOM from "react-dom";

/**
 * nekdy ve vzdalene budoucnosti by bylo vhodne tohle cele prepsat.
 * Problemy:
 *  render neni pure function, ma spoustu logiky ktera by mela byt jinde
 *  v propsech hodnoty chodi jako string, ale jsou potreba checkvoat jako float, takze se ruzne pretypovavaji
 *  Ceny se pocitaji na nekolika mistech velice podobnym zpusobem, namisto abu se spocitali jednou.
 */
class QProduct extends PureComponent{
    constructor(props) {
        super(props);

        this.state = {}
        this.quantity = null

        this.pricingMapFieldName = '';
    }

    /**
     * Obcas je potreba nazkalade jednoho pole prepocitat jine, proto tolik propoctu
     * @param way cesta i s indexem napr. view/customData/productsData/groups/0/lines/3/discount_amount
     * @param val nova hodnota pole
     */
    valChange(way, val) {
        let value = val
        if (way.includes("discount_amount")) {
            let addVal
            let discount = val;
            if (this.props.data.discount_select) {
                discount = (sAction.parseNumber(this.props.data.price_after_discount) / 100) * val;
                let tmpTotalPrice = sAction.parseNumber(this.props.data.price_after_discount) - discount;
                addVal = 100 * Math.abs((tmpTotalPrice - this.props.data.list_price) / this.props.data.list_price)
            } else {
                addVal = this.props.data.list_price - (this.props.data.price_after_discount - discount)
            }

            const addFieldWay = this.props.way + "/" + this.props.ind + "/price_after_add_discount"
            sAction.updateDetailCustom("field", {way: addFieldWay, value: addVal})

        } else if (way.includes("discount_select") && this.props.data.discount_amount) {
            let addVal;
            if (val) {
                let discount = (sAction.parseNumber(this.props.data.price_after_discount) / 100) * this.props.data.discount_amount;
                let tmpTotalPrice = sAction.parseNumber(this.props.data.price_after_discount) - discount;
                addVal = 100 * Math.abs((tmpTotalPrice - this.props.data.list_price) / this.props.data.list_price)
            } else {
                addVal = this.props.data.list_price - (this.props.data.price_after_discount - this.props.data.discount_amount)
            }
            const addFieldWay = this.props.way + "/" + this.props.ind + "/price_after_add_discount"
            sAction.updateDetailCustom("field", {way: addFieldWay, value: addVal})

        } else if (way.includes("price_after_add_discount")) {
            const addFieldWay = this.props.way + "/" + this.props.ind + "/discount_amount"
            sAction.updateDetailCustom("field", {way: addFieldWay, value: 0})
        }

        sAction.updateDetailCustom("field", {way: way, value: value});
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    uploadImage = (val) => {
        const way = this.props.way + "/" + this.props.ind + "/picture";
        sAction.updateDetailCustom("field", {way: way, value: val});
    }

    productCallback(way, key, item) {
        if (item.id != undefined && item.id != "") {
            //product template has been selected
            sAction.getProductTemplate(item.id, 'prodName', way, key);
        } else {
            sAction.updateDetailCustom("prodName", {way: way + '/' + key, item: item, template: false});
        }
    }

    prodInfo(params){
        window.open("#detail/Products/"+params.id, '_blank')
    }

    blurDescription(way, val){
        //this.setState({descFocused : false});
        if (sAction.dataGet(way) !== val) {
            this.valChange(way, val);
        }
    }

    setDescription(productIndex) {
        let line = sAction.dataGet(`${this.props.way}/${productIndex}`)
        line = line.set("descFocused", !line.descFocused)
        sAction.dataSet(`${this.props.way}/${productIndex}`, line)
    }

    isDisabled(fieldDisable, readOnly, overrideDisable = null){
        if(typeof overrideDisable === "boolean"){
            return overrideDisable
        }

        return fieldDisable || readOnly

    }

    handleClickOutside = event => {
        if (this.state[this.pricingMapFieldName]) {
            const domNode = ReactDOM.findDOMNode(this);

            if (!domNode || !domNode.contains(event.target)) {
                this.setState({[this.pricingMapFieldName]: false,})
            }
        }
    }

    render(){
        const conf = confProd.fields;
        var data = this.props.data;
        const way = this.props.way;
        const ind = this.props.ind;
        const itway = way+"/"+ind;
        let readonly = this.props.readonly ?? false;
        let pricingMap = data.pricing_map
        if (pricingMap && Map.isMap(data.pricing_map)) {
            pricingMap = data?.pricing_map.toJS()
        }
        const isRightPanel = way?.includes("rightPanel");

        const dataProd = {value : data.name, id_value: data.id, searchFields : ["name", "mft_part_num", "mft_part_num_int"]};
        const module = "ProductTemplates";

        var taxClassOptions =[];
        sAction.app_strings.tax_class_dom.forEachObject((lbl, key) => {
            taxClassOptions.push({value: key, label: lbl});
        });

        var curId = this.props.currency_id;
        if(!curId){
            curId = "-99";
        }
        const discountOptions = [{value: "0", label: sAction.currencies[curId].symbol}, {value: "1", label: "%"}];
        const discountVal = data.discount_select;


        let buttons = [];
        if(data.id && !readonly){
            buttons.push({id: "fullForm", className: "icon-expandIcon qProductProdNameIcon",  onClick: this.prodInfo, params: {id : data.id}});
        }

        let descClass = "productLineDescription";
        const keyPre = Date.now();

        var renderComp = [];

        var self = this;

        var relateClass = "qProductProdNameField";

        if(readonly){
            relateClass += " readonlyRel";
        }

        conf.forEach(field => {
            if(!field.visible){
                return;
            }

            switch(field.type){
                case "number":
                    let quantity = data[field.name]
                    if(field.name === "quantity" && !data.quantity){
                        quantity = 1
                    }

                    renderComp.push(
                        <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                id={field.name}
                                type={!isRightPanel ? "number" : "text"} // v rightpanelu neni misto, tak aby se tam vubec neco zobrazilo
                                defaultValue={quantity}
                                autoComplete="of"
                                disabled={field.disabled || readonly}
                                key={keyPre+"_"+field.name}
                                className="quoteProductField quoteNumberInput"
                            onBlur={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "relateProd":
                    renderComp.push(
                        <div className={relateClass} style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                            <Relate
                                newRecord={false}
                                data={dataProd}
                                module={module}
                                buttons={buttons}
                                key={keyPre+"_"+field.name}
                                InputProps={{readOnly: readonly}}
                                callback={(item) => self.productCallback(way, ind, item)}
                            />
                        </div>
                    );
                    break;
                case "text":
                    renderComp.push(
                        <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                className="quoteProductField"
                                id={field.name}
                                type="text"
                                autoComplete="of"
                                disabled={field.disabled || readonly}
                                defaultValue={data[field.name]}
                                key={keyPre+"_"+data[field.name]}
                                onBlur={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "tax_class":
                    renderComp.push(
                        <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                            <Select
                                open={false}
                                name={field.name}
                                options={taxClassOptions}
                                defaultValue={data[field.name]}
                                disabled={field.disabled || readonly}
                                key={keyPre+"_"+field.name}
                                onChange={(ev) => self.valChange(itway+"/"+field.name, ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "discount_select":
                    renderComp.push(
                        <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                            <Select
                                open={false}
                                name={field.name}
                                options={discountOptions}
                                defaultValue={discountVal}
                                disabled={field.disabled || readonly}
                                key={keyPre+"_"+field.name}
                                onChange={(ev) => self.valChange(itway+"/"+field.name, +ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "mj_select":
                    //Vzhledem k tomu ze pro tohle pole neexsituje ciselnik musi pokazde "vygenerovat" novy,
                    //z hodnot co prijdou z BE, ale pouze pokud jsou mj a mj rozdilne
                    //Do default value se potom tim padem nastavuje klic "ciselniku"
                    const mjOptions = [{value: "mj", label: data.mj}];

                    if(data.mj !== data.mj2 && data.mj2) {
                        mjOptions.push({value: "mj2", label: data.mj2})
                    }

                    renderComp.push(
                        <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                            <Select
                                open={false}
                                name={field.name}
                                options={mjOptions}
                                defaultValue={data.selected_mj === "mj2" ? "mj2" : "mj"}
                                disabled={field.disabled || readonly}
                                key={keyPre+"_"+field.name}
                                onChange={(ev) => self.valChange(`${itway}/selected_mj`, ev.target.value)}
                            />
                        </div>
                    );
                    break;
                case "price":
                    let val = 0
                    let extraEvents = {}
                    if (field.name == "discount_price") {
                        let discount = sAction.parseNumber(data.price_after_add_discount);
                        if (parseFloat(data.discount_select)) {
                            discount = (sAction.parseNumber(data.list_price) / 100) * discount;
                        }

                        val = sAction.parseNumber(data.list_price) - discount;
                    } else if (field.name == "total_price") {
                        let discount = sAction.parseNumber(data.price_after_add_discount);
                        if (parseFloat(data.discount_select)) {
                            discount = (sAction.parseNumber(data.list_price) / 100) * discount;
                        }

                        if(data.selected_mj === "mj2"){
                            val = ((sAction.parseNumber(data.list_price) - discount) * sAction.parseNumber(data.price_mj2)) * data.quantity;
                        } else {
                            val = (sAction.parseNumber(data.list_price) - discount) * data.quantity;
                        }

                    } else if(field.name === "price_after_add_discount") {
                        //Pokud je nastavena firemni sleva a produkt ma "nepodleha sleve dokladu" na false nebo vubec neni
                        // aplikovat firemni slevu na toto pole
                        if (this.props.accountDiscount && !data.price_after_add_discount && !pricingMap?.do_not_discount) {
                            val = this.props.accountDiscount
                            if (!data.discount_select) {
                                val = (data.list_price / 100) * this.props.accountDiscount
                            }
                        } else {
                            val = data.price_after_add_discount
                        }
                    } else {
                        val = sAction.parseNumber(data[field.name]);
                    }


                    let extraClass = ""
                    let disabled = this.isDisabled(field.disabled, readonly)
                    let component = null
                    //Pokud je v configu v poli nasteveno ze ma zobrazovat kompetenci prida se tooltip s textem
                    //Problem je ze vzhledem k velikosti renderu nestihne se provolat onmouseenter a popup zustava zobrazen
                    if (field.competence && sAction.checkCompetence(way, data)) {
                        extraClass = "isUnderCompetence"
                        extraEvents = {
                            onMouseEnter: () => this.setState({[field.name]: true}),
                            onMouseLeave: () => this.setState({[field.name]: false}),
                        }
                        component = <CustomTooltip content={sAction.translate("LBL_IS_UNDER_COMPETENCE")} direction="top" extraClass={"tooltipCenter"}/>
                    }
                    //to stejne ale s mapou z ceniku
                    if (field.showPricingMap && pricingMap) {
                        this.pricingMapFieldName = field.name
                        extraClass = "hasPricingMap"
                        extraEvents = {
                            onClick: () => {
                                this.setState(prevState => ({[field.name]: !prevState[field.name]}))
                            },

                        }
                        component = <Pricing data={pricingMap}/>
                    }

                    //Kdyz zadavam produkt textove nechci mit list price disabled
                    if(!data.product_template_id &&  field.name === "list_price"){
                        disabled = this.isDisabled(field.disabled, readonly, false)
                    }

                    if(!disabled && field.name === "list_price"){
                        val = sAction.parseNumber(data.list_price)
                        //Protoze jednou prijde jako "15.0000" jindy 15.00 obcas "15" musim timto blbym stylem pretypovat a porovnat
                        if (parseFloat(val).toFixed(2) !== parseFloat(data.price_after_discount).toFixed(2)) {
                            self.valChange(itway + "/price_after_discount", sAction.parseNumber(val), field.name)
                        }
                    }

                    renderComp.push(
                        <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                            {this.state[field.name] ? component : null}
                            <TextField
                                className={`priceInput quoteProductField ${extraClass}`}
                                margin="normal"
                                variant="outlined"
                                id={field.name}
                                type="text"
                                key={keyPre+"_"+field.name}
                                autoComplete="of"
                                disabled={disabled}
                                defaultValue={sAction.formatNumber(val)}
                                onBlur={(ev) =>
                                    self.valChange(itway+"/"+field.name, sAction.parseNumber(ev.target.value), field.name)}
                                {...extraEvents}
                            />
                        </div>
                    );
                    break;
                case "checkbox":
                    renderComp.push(
                        <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                            <CheckBox
                                name={field.name}
                                defaultValue={data[field.name]}
                                disabled={field.disabled || readonly}
                                onChange={(value) => self.valChange(itway+"/"+field.name, +value)}
                            />
                        </div>
                    );
                    break;
                case 'semaphore':
                    let semaphoreValue = 'grey';

                    if (data.cost_price) {
                        let discount = sAction.parseNumber(data.discount_amount);
                        if (data.discount_select) {
                            discount = (sAction.parseNumber(data.list_price) / 100) * discount;
                        }
                        let margin = (sAction.parseNumber(data.list_price) - discount) / sAction.parseNumber(data.cost_price);

                        if (margin >= 1.2) {
                            semaphoreValue = 'green';
                        } else if (margin >= 1.1) {
                            semaphoreValue = 'orange';
                        } else {
                            semaphoreValue = 'red';
                        }
                    }

                    renderComp.push(
                        <div style={{width: field.width+"%"}} key={keyPre+"_"+field.name}>
                            <Enum
                                option={sAction.app_strings.margin_check_list}
                                colors={sAction.app_strings.margin_check_list_colors}
                                value={semaphoreValue}
                                onClick={e => {}}
                                extraClass={'qProduct'}
                            />
                        </div>
                    );
                    break;
            }

        });

        var descComp = null;
        if(data.descFocused){
            descComp = <>
                <Image picture={data.picture} onChange={this.uploadImage} way={itway} />
                <TextField
                    className={descClass}
                    multiline={true}
                    type="text"
                    margin="normal"
                    variant="outlined"
                    rows={10}
                    key={keyPre + "_description"}
                    onBlur={(ev) => this.blurDescription(itway+"/description", ev.target.value)}
                    defaultValue={data.description}
                    autoFocus={true}
                />
            </>
        }
        else{
            descComp = <div>{data.description}</div>
        }
        var prodlinesIcons = <div className="qProductLineButtonContainer">
            <div className="prodLineIcon"/>
            <div className="prodLineIcon"/>
        </div>;

        if(!readonly){
            prodlinesIcons = <div className="qProductLineButtonContainer">
                <div className="icon-deleteIcon" onClick={() => sAction.updateDetailCustom("delItem", {way: way, index: ind})}/>
                <div className="icon-editDesc" onClick={() => this.setDescription(ind)}/>
            </div>;
        }


        return (
            <Draggable draggableId={`${this.props.groupIndex}-${ind}`} index={ind}>
                {provided => (<div {...provided.draggableProps} ref={provided.innerRef}>
                        <div className="qProductLine">
                            {!readonly ? <div className="qProductLineButtonContainer">
                                <div className="icon-Sortable draggable hoverBlue" {...provided.dragHandleProps}
                                     onMouseDown={() => document.activeElement.blur()}/>
                            </div> : null }
                            <div className="qProductLineButtonContainer">
                                <div
                                    onClick={() => sAction.rightPanelDetail("Products", data.id)}
                                    className={data.id || data.dragged ? "icon-eye hoverBlue" : ""}
                                    style={{margin: "auto", "marginLeft": "2px", "width": "19px", fontSize: 14, visibility: !isRightPanel ? 'visible' : 'hidden'}}>
                                </div>
                                {data.picture &&
                                <div
                                    onClick={() => sAction.popup("image",{url:sAction.param.files+"image/"+data.picture})}
                                    className="icon-preview hoverBlue"
                                    style={{margin: "auto", "marginLeft": "2px", "width": "19px", fontSize: 13}}>
                                </div>}
                            </div>
                            {prodlinesIcons}
                            {renderComp}
                        </div>
                        <div className="quoteDescContainer">
                            {descComp}
                        </div>
                    </div>
                )}
            </Draggable>);
    }
}

export default QProduct
