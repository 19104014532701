export default function breadcrumb(recordName) {
    const hash = window.location.hash.replace("#", "");
    const uri = hash.split("/");
    let module = null;
    let breadcrumb = [];
    let moduleName = "";
    if (uri[0] === "list") {
        module = uri[1];
        moduleName = this.app_strings.moduleList[module];
        breadcrumb.push({type: "link", "url": "#" + hash, label: moduleName});
    } else if (uri[0] === "detail") {
        module = uri[1];
        moduleName = this.app_strings.moduleList[module];
        breadcrumb.push({type: "link", "url": "#list/" + uri[1], label: moduleName});
        if (recordName && recordName !== "null null") {
            breadcrumb.push({type: "text", label: recordName})
        }
    } else if (uri[0] === "home" || hash === "" || uri[0] === "login") {
        moduleName = this.app_strings.moduleList["Home"];
        breadcrumb.push({type: "link", "url": "#" + hash, label: moduleName});
    } else if (uri[0] === "calendar") {
        moduleName = this.app_strings.moduleList["Calendar"];
        breadcrumb.push({type: "link", "url": "#" + hash, label: moduleName});
    } else if (uri[0] === "globalSearch") {
        breadcrumb.push({type: "text", label: this.translate("LBL_GLOBAL_SEARCH")});
    } else if (uri[0] === "leadConvert") {
        moduleName = this.app_strings.moduleList["Leads"];
        breadcrumb.push({type: "link", "url": "#list/Leads", label: moduleName});
        breadcrumb.push({type: "link", "url": "#detail/Leads/" + uri[1], label: recordName});
        breadcrumb.push({type: "text", label: this.translate("LBL_CONVERTLEADD", "Leads")});
    } else if (uri[0] === "admin") {
        moduleName = this.app_strings.moduleList["Administration"];
        breadcrumb.push({type: "link", "url": "#" + uri[0], label: moduleName});
    } else if (uri[0] === "import") {
        module = uri[1];
        moduleName = this.app_strings.moduleList[module];
        breadcrumb.push({type: "link", "url": "#list/" + uri[1], label: moduleName});
        breadcrumb.push({type: "text", label: "Import"});
    } else if (uri[0] === "customReports") {
        breadcrumb.push({
            type: "link",
            "url": "#list/acm_coripoReports",
            label: this.app_strings.moduleList["acm_coripoReports"]
        });
        breadcrumb.push({type: "text", label: ""});
    } else if (uri[0] === "merge") {
        const history = this.dataGet("history/urls")
        if (history) {
            module = history?.last(-1)?.split("/")?.slice(-1)[0]
            moduleName = this.app_strings.moduleList[module];
            breadcrumb.push({type: "link", "url": "#list/" + module, label: moduleName});
            breadcrumb.push({type: "text", label: this.translate("LBL_RECORDS_MERGE")})
            //Znovu se nastavi moduleName aby title byl preklad LBL_RECORDS_MERGE a ne nazev modulu
            moduleName = this.translate("LBL_RECORDS_MERGE")
        }
    } else {
        module = uri[0];
        moduleName = this.app_strings.moduleList[module];
        breadcrumb.push({type: "link", "url": "#" + hash, label: moduleName});
    }

    if (recordName && recordName !== "null null") {
        document.title = recordName;
    } else if (moduleName && moduleName !== "null") {
        document.title = moduleName;
    } else {
        document.title = "coripo";
    }

    // document.title = recordName || moduleName || "";
    return breadcrumb;
}
