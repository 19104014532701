import React from "react";
import PureComponent from "../pure";
import Text from "../formElements/InputText";
import sAction from "sAction";
import Loader from "../loader";

class ListViewSearch extends PureComponent {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.inputText = React.createRef();
        this.state = {
            inputVal: "",
            searchedInputVal: "",

            resultOpen: false,
            loaded: false,
            searchContextCnt: 0
        }
        const prefix = this.props.prefix
        // kdyz je popup listview -> promazat ulozene hledani
        if (prefix === "conf/popup/data/listView" || prefix === "rightPanel/data/listView"){
            this.clearSearchContext()
        }
    }

    search() {
        const val = this.input.current.value;
        const prefix = this.props.prefix;
        this.setState({ searchedInputVal: val });

        if (val && val.trim() !== "") this.addSearchContext(val.trim())

        const searchData = this.searchContext()
        sAction.listGlobalSearch(searchData, prefix, () => { this.onInputChange("") });
        this.onInputChange("")
    }

    addSearchContext(val) {
        const prefix = this.props.prefix;
        const module = sAction.dataGet(prefix + "/modul")
        const view = this.props.prefix.split("/").slice(-1)[0]
        let searchContext = sAction.getStorage("searchContext")

        if (!searchContext) searchContext = {}
        if (!searchContext[`${module}_${view}`]) {
            searchContext[`${module}_${view}`] = {
                searchData: [{
                    key: 0,
                    val: val
                }]
            }
        } else {
            if (searchContext[`${module}_${view}`].searchData.find(item => item.val === val)) return
            searchContext[`${module}_${view}`].searchData.push({
                key: searchContext[`${module}_${view}`].searchData.length,
                val: val
            })
        }
        sAction.setStorage("searchContext", searchContext)
    }

    searchContext() {
        const prefix = this.props.prefix;
        const module = sAction.dataGet(prefix + "/modul")
        const view = this.props.prefix.split("/").slice(-1)[0]
        let searchContext = sAction.getStorage("searchContext")
        let searchContextArr = [];

        if (searchContext && searchContext[`${module}_${view}`]) {
            searchContextArr = searchContext[`${module}_${view}`].searchData
        }

        return searchContextArr
    }

    clearSearchContext() {
        const prefix = this.props.prefix;
        const module = sAction.dataGet(prefix + "/modul")
        const view = this.props.prefix.split("/").slice(-1)[0]
        let searchContext = sAction.getStorage("searchContext")

        if (searchContext && searchContext[`${module}_${view}`]) {
            searchContext[`${module}_${view}`] = {
                searchData: []
            }
        }
        sAction.setStorage("searchContext", searchContext)
        if(this.state.loaded) this.forceUpdate()
    }

    dropFromContextSearch(index) {
        const prefix = this.props.prefix;
        const module = sAction.dataGet(prefix + "/modul")
        const view = this.props.prefix.split("/").slice(-1)[0]
        let searchContext = sAction.getStorage("searchContext")

        let contextArr = []
        searchContext[`${module}_${view}`].searchData.forEach(item => {
            if (item.key !== index) contextArr.push(item)
        })

        if (searchContext[`${module}_${view}`]) {
            searchContext[`${module}_${view}`] = {
                searchData: contextArr
            }
        }
        sAction.setStorage("searchContext", searchContext)
        this.search()
        this.setState({ inputVal: "", searchedInputVal: "" });

        if(this.state.loaded) this.forceUpdate()
    }

    newRecord() {
        const params = {
            module: this.props.module,
            way: this.props.prefix
        }
        sAction.detailNewRecord(params)
    }

    componentDidMount() {
        this.state.loaded = true;
        this.setDefaultValue();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.module !== prevProps.module || this.props.data !== prevProps.data) {
            this.setDefaultValue();
        }
    }

    clearInputValue = () => {
        this.setState({ inputVal: "" });
    }

    setDefaultValue = () => {
        let defaultValue = "";
        // const data = this.props.data;
        
        // this will renew search after reload dont use now
        // if (data.toJS().length > 0) {
        //     data.forEach((filter, index) => {
        //         if (filter.field === "*" && filter.filters) {
        //             defaultValue = filter.filters.toJS()[0].value;
        //         }
        //     });
        // }

        this.setState({ inputVal: defaultValue, searchedInputVal: defaultValue });
    }

    onInputChange = (e) => {
        this.setState({ inputVal: e });
    }

    onClearButton = () => {
        this.clearInputValue();
        this.clearSearchContext()
        this.search();
    }

    getSearchContext() {
        const searchContext = this.searchContext()

        if (!searchContext || searchContext.length === 0) {
            // this.setState({ resultOpen: false, searchContextCnt: 0 })
            this.state.resultOpen = false;
            this.state.searchContextCnt = 0;
            return null;
        }

        this.state.resultOpen = true;
        this.state.searchContextCnt = searchContext.length;
        // this.setState({ resultOpen: true, searchContextCnt: searchContext.length })
        if (this.state.loaded) {
            let resultList = [];
            let searchContextReverse = searchContext.reverse()

            searchContextReverse.forEach((item, i) => {
                let lineClass = "searchContextResultLine";
                resultList.push(
                    <div tabIndex={i} key={i} className={lineClass} >
                        {sAction.decodeHTMLEntities(item.val)}
                        <span
                            className="icon-closeIconLight clearButton"
                            onClick={() => this.dropFromContextSearch(item.key)}
                        />
                    </div>
                );
            });

            return <div className="searchContextResult" >{resultList}</div>;
        }
        return <div className="searchContextResult"><div style={{ height: "70%", top: "25px" }} className="searchContextNoRecords">{<Loader />}</div></div>;
    }

    render() {
        const searchResult = this.getSearchContext();

        return (
            <div className="flexInLine flexVerticalCenter">
                <div className="listViewSearchWrapper">
                    <div className={"listViewSearch" + (this.state.resultOpen ? " shadow" : "")}>
                        <div>
                            <div
                                onClick={() => this.search()}
                                className="icon-search listViewSearchIcon"
                            />
                            {this.state.resultOpen && <div className="activeBadge" onClick={() => this.search()}><p>{this.state.searchContextCnt}</p></div>}
                        </div>
                        <Text
                            myRef={this.input}
                            onKeyUp={event => {
                                if (event.keyCode == 13) this.search()
                                if (event.keyCode == 27) this.onClearButton(event)
                            }}
                            placeholder={sAction.translate("LBL_LIST_SEARCH_GLOBAL_DESC")}
                            autoFocus={true}
                            clearButton={true}
                            onChange={(e) => { this.onInputChange(e.target.value) }}
                            value={this.state.inputVal}
                            onClearButton={this.onClearButton}
                        />
                        <div className="listViewSearchButton" onClick={() => this.search()}>
                            {sAction.translate("LBL_LIST_SEARCH_GLOBAL")}
                        </div>
                    </div>

                    {searchResult}
                </div>

                <div className="listViewSearchButtonAdd" onClick={() => this.newRecord()}>
                    <div className="icon-addIcon">

                    </div>
                </div>
            </div>
        );
    }
}
export default ListViewSearch;
