import React from 'react'
import PureComponent from '../pure'

import TickBox from '../formElements/TickBox'
import CalExternalEvents from './calExternalEvents'
import Button from "../formElements/Button"

import sAction from "sAction"

class CalFilters extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      tabSelected: sAction.dataGet("conf/user/orders_planner") === '1' ? 'events' : 'filter',
      userIsOrdersPlanner: sAction.dataGet("conf/user/orders_planner"),
    }
  }

  updateFilters(name, value) {
    sAction.dataSet("calendar/filters/" + name, value)
    // this.applyFilters();

    //propsani do nastaveni
    sAction.dataSet("calendar/setting/" + name, value)
    let calSettings = sAction.dataGet("calendar/setting").toJS()
    calSettings[name] = value
    sAction.rest.post("saveCalSettings", calSettings, (returnData) => {
      if (!returnData) {
        sAction.error(sAction.translate("LBL_ERROR_SAVING", "Calendar"))
      }

      sAction.calPlanReload()
    })
  }

  applyFilters() {
    sAction.applyCalFilters()
  }

  componentDidUpdate() {
  }

  updateStateFilters(field, key, val) {
    let pomStates = sAction.dataGet("calendar/filters/" + field).toJS()
    if (val) {
      pomStates.push(key)
    } else {
      let pomInd = pomStates.indexOf(key)
      pomStates.splice(pomInd, 1)
    }

    this.updateFilters(field, pomStates)

  }

  getEventClass(val) {
    switch (val) {
      case 'Planned':
      case 'Not Started':
        return 'eventNotStarted'
      case 'Pending Input':
        return 'eventPending'
      case 'Held':
      case 'Completed':
        return 'eventSuccess'
      case 'Not Held':
      case 'Deferred':
        return 'eventFailed'
      default:
        return 'eventInProgress'
    }
  }

  render() {
    let classPom = "calFilters"
    let visibClass = ""
    let self = this

    // TODO zkusit to zase neresit pres storage, ale vzhledem k tomu jak casto se musi vse prenacitat, tak budto store, nebo to budeme muset ukladat prez BE?
    const filt = localStorage.getItem('acmCalFiltersVisibility')
    if (filt == undefined || filt == 'visible') {
      classPom += " showFilters"
    } else {
      visibClass = " calHidden"
    }

    var meetOptions = []
    sAction.app_strings.meeting_status_dom.forEachObject((lbl, key) => {
      meetOptions.push({
        value: key,
        label: lbl
      })
    })

    var callOptions = []
    sAction.app_strings.call_status_dom.forEachObject((lbl, key) => {
      callOptions.push({
        value: key,
        label: lbl
      })
    })

    var taskOptions = []
    sAction.app_strings.task_status_dom.forEachObject((lbl, key) => {
      taskOptions.push({
        value: key,
        label: lbl
      })
    })

    let data = this.props.data.toJS()

    let meetStateClass = "filterRowState" + (!data.showMeetings ? " calNoDisplay" : '')
    let callStateClass = "filterRowState" + (!data.showCalls ? " calNoDisplay" : '')
    let taskStateClass = "filterRowState" + (!data.showTasks ? " calNoDisplay" : '')

    var pomMeetRender = []
    meetOptions.forEachObject(opt => {
      pomMeetRender.push(this.generateTickBox(opt, 'meet', data))
    })

    var pomCallRender = []
    callOptions.forEachObject(opt => {
      pomCallRender.push(this.generateTickBox(opt, 'call', data))
    })

    var pomTaskRender = []
    taskOptions.forEachObject(opt => {
      pomTaskRender.push(this.generateTickBox(opt, 'task', data))
    })

    var openButtonClass = "navFiltToogle icon-arrowLeft"
    if (this.props.data.filtersDisplayed) {
      openButtonClass = "navFiltToogle navToogle icon-arrowRight"
    }

    const meetFilterSection = this.buildFilterSection(!+data.showMeetings ? " notSelected" : "", meetStateClass, data, pomMeetRender, 'showMeetings', 'LBL_SETTINGS_MEETINGS_SHOW')
    const callFilterSection = this.buildFilterSection(!+data.showCalls ? " notSelected" : "", callStateClass, data, pomCallRender, 'showCalls', 'LBL_SETTINGS_CALLS_SHOW')
    const taskFilterSection = this.buildFilterSection(!+data.showTasks ? " notSelected" : "", taskStateClass, data, pomTaskRender, 'showTasks', 'LBL_SETTINGS_TASKS_SHOW')

    const filters =
      [
        <div key={'calFiltersContainer'} className={"calFiltersContainer" + visibClass}>
          <div className="calFiltersSection firstSection">
            <div className={"calFiltersRow sectionRow" + (!+data.showCompleted ? " notSelected" : "")}>
              <TickBox className="calFiltersTick" name="showCompleted" checked={!!data.showCompleted}
                       onChange={(value) => this.updateFilters("showCompleted", value)}/>
              <span className="">{sAction.translate("LBL_SETTINGS_COMPLETED_SHOW", "Calendar")}</span>
            </div>
          </div>
          {meetFilterSection}
          {callFilterSection}
          {taskFilterSection}
        </div>
      ]

    return (
      <div className={classPom}>
        <div className={"calBoardHeader" + visibClass}>
          <Button key={`calBoardHeader-filters`} className="calBoardHeader"
                  onClick={() => this.handleTabSelect('filter')}>
            <div className="icon-CalFilterIcon calHeaderIcon"></div>
            <span className="calFilterTitle">&nbsp;{sAction.translate("LBL_FILTERS_TITLE", "Calendar")}</span>
          </Button>
          {this.state.userIsOrdersPlanner === '1' && (
            <Button key={`calBoardHeader-events`} className="calBoardHeader"
                    onClick={() => this.handleTabSelect('events')}>
              <div className="icon-calendar calHeaderIcon"></div>
              <span className="calFilterTitle">&nbsp;{sAction.translate("LBL_EVENTS_TITLE", "Calendar")}</span>
            </Button>
          )}
        </div>
        {this.state.tabSelected === 'filter' && (
          filters
        )}
        {(this.state.tabSelected === 'events' && this.state.userIsOrdersPlanner === '1') && (
          <CalExternalEvents key={`CalExternalEvents`} data={data} events={this.state.events}
                             reload={this.props.data.reloadExternal}/>
        )}
        <div className={openButtonClass} onClick={() => sAction.toggleCalcFilter()}/>
      </div>
    )
  }

  buildFilterSection(secRowClass, StateClass, data, pomRender, show, lbl) {
    // TODO predelat do komponenty
    return (
      <div className="calFiltersSection">
        <div className={"calFiltersRow sectionRow" + secRowClass}>
          <TickBox
            className="calFiltersTick"
            name={show}
            checked={!!data[show]}
            onChange={(value) => this.updateFilters(show, value)}/>
          <span className="">
            {sAction.translate(lbl, "Calendar")}
          </span>
        </div>
        <div className={StateClass}>
          {pomRender}
        </div>
      </div>
    )
  }

  handleTabSelect = (tabID) => {
    this.setState({tabSelected: tabID})
  }

  generateTickBox(opt, type, data) {
    let selVal = false
    let itemClass = " notSelected"
    let typeSelector = `${type}States`
    if (data[typeSelector]?.indexOf(opt.value) >= 0) {
      selVal = true
      itemClass = " selected"
    }
    return (
      <div key={`${typeSelector}${opt.value}`} className="calFiltersRow">
        <TickBox className="calFiltersStateTick"
                 checked={!!selVal}
                 onChange={(value) => this.updateStateFilters(`${typeSelector}`, opt.value, value)}/>
        <span className={"calFilterLbl " + this.getEventClass(opt.value) + itemClass}>{opt.label}</span>
      </div>
    )
  }
}

export default CalFilters
