import $ from "jquery";

export default class rest {
  constructor(parent) {
    this.url = parent.param.server;
    this.token = null;
    this.parent = parent;
    this.xhr = null;
  }

  post(action, data, complete, cancelPrevisouRequest = true) {
    this.call(
      action,
      "POST",
      data,
      function (msg) {
        complete(msg);
      },
      cancelPrevisouRequest
    );
  }
  get(action, complete, cancelPrevisouRequest = true) {
    this.call(
      action,
      "GET",
      null,
      function (msg) {
        complete(msg);
      },
      cancelPrevisouRequest
    );
  }
  delete(action, data, complete) {
    this.call(action, "DELETE", data, function (msg) {
      complete(msg);
    });
  }

  call(action, method, data, complete, cancelPrevisouRequest) {
    var auth = this.getAuth();
    var self = this;
    if (this.xhr != null && cancelPrevisouRequest == true) {
      this.xhr.abort();
    }

    if (this.parent?.param?.xdebugEnabled) {
      action += (action?.includes("?") ? "&" : "?") + "XDEBUG_SESSION_START=1";
    }

    this.xhr = $.ajax({
      method: method,
      contentType: "application/json",
      url: self.url + "/" + action,

      data: JSON.stringify(data),
      headers: auth,
      success: (data) => {
        //zobrazeni fatalnejsich chyb, ktere mohou prerusit routovani atd.
        if (typeof data === "object" && data.status === "error") {
          if (data.reason != undefined) {
            self.parent.dsClear();
            if (self.parent.dataGet("rightPanel/show") == true) {
              self.parent.dsAdd("set", "rightPanel/content", "error");
              self.parent.dsAdd("set", "conf/load", false);
              self.parent.dsAdd("set", "rightPanel/data", {
                state: false,
                type: data.reason,
              });
            } else {
              self.parent.dsAdd("set", "conf/view", "error");
              self.parent.dsAdd("set", "conf/load", false);
              self.parent.dsAdd("set", "view", {
                state: false,
                type: data.reason,
              });
            }

            self.parent.dsProcess();
          } else {
            self.parent.unLoad();
            self.parent.error(self.parent.translate(data.msg || data.message));
          }
        }

        complete(data);

        //zobrazeni dalsich chybovych hlasek
        if (typeof data === "object" && data.status === "warning") {
          setTimeout(function () {
            self.parent.error(self.parent.translate(data.msg || data.message));
          }, 2500);
        }
      },
      error: (XMLHttpRequest, textStatus, errorThrown) => {
        if (
          (XMLHttpRequest.status == "401" || XMLHttpRequest.status == 0) &&
          XMLHttpRequest.statusText != "abort"
        ) {
          if(action === "getNotifyCount" || action === "recalcTopRecent"){
            return;
          }

          if (!window.location.hash.startsWith('#login')) {
            if (XMLHttpRequest.status == "401") {
              // Upraveny zpusob routovani loginu
              clearInterval(this.parent.notificationInterval);
              self.parent.routeLogin();

              // // Stary zpusob routovani, ktery presmeruje na login
              // window.location.hash = "#login";
              // this.get("trns", data => {
              //   self.parent.app_strings = data.lang;
              //   self.parent.renderReact();
              // });
            } else {
              self.parent.error(self.parent.translate("LBL_SERVER_ERROR"));
              self.parent.unLoad();
            }
          } else {
            clearInterval(this.parent.notificationInterval);
            self.parent.routeLogin();
          }
        }
      },
    });
  }
  getAuth() {
    var token = this.getCookie("sID");
    var auth = {
      sID: token,
    };

    if (this.parent.deviceType != undefined) {
      auth["device"] = this.parent.deviceType;
    }

    return auth;
  }
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  }
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1);
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return null;
  }

  /**
   * Metoda pocita s formatem dat v coripo standardu, jinak nebude fungovat spravne.
   * @param action
   * @param method
   * @param data
   * @returns {Promise<any>}
   */
  async fetchData(action, method, data = null) {
    let self = this
    let sId = this.getAuth()
    let url = self.url + "/" + action
    if (this.parent?.param?.xdebugEnabled) {
      url += (action?.includes("?") ? "&" : "?") + "XDEBUG_SESSION_START=1";
    }

    let fetchParams = {
      method: method,
      contentType: "application/json",
      headers: new Headers({
        'content-type': 'application/json',
        'sId': sId.sID
      }),
    }

    if(method === "POST" && data){
      fetchParams.body = JSON.stringify(data)
    }
    let fetchedData = await fetch(url, fetchParams).then(response => response.json()).then(result => {
      return result
    }).catch(responseError => {
      console.error('Error', responseError)
      console.warn('You propably have error in your BE method returned data is not valid JSON')
    })

    if (!fetchedData.status) {
      throw fetchedData.errorMessage
    }

    return fetchedData.message
  }
}
