import React from "react";
export default class Tree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: null,
      selected: [],
      groupState: {}, // groupId => open/close
      allOpen: false,
    };
    this.tree = React.createRef();
    this.hover = React.createRef();
    this.select = React.createRef();

    this.openLevel = null;
    this.groupInitState = [];
    if(props.openLevel != undefined){
      this.openLevel = props.openLevel
    }
  }
  componentDidMount(){
    this.openLevel = null;
    this.setState({
      groupState: this.groupInitState,
    })
  }
  componentDidUpdate() {
    const tree = this.tree.current.querySelector(".acmTreeGroupMain")
    const treeTop = tree.getBoundingClientRect().top;
    if (this.state.select != null) {
      this.select.current.style.display = "block";
      const targetTop = this.state.select.getBoundingClientRect().top;
      this.select.current.style.top = targetTop - treeTop + tree.scrollTop + "px";
    } else {
      var main = this.tree.current.querySelector(".acmTreeGroupMain");
      const targetTop = main.getBoundingClientRect().top;
      this.select.current.style.top = targetTop - treeTop + "px";
      this.select.current.style.display = "block";
    }
  }

  createGroup(group, first = false,level=0) {
    var open = false;
    if (this.state.groupState[group.id] == "open" || group.id == "main") {
      open = true;
    }
    var groupState = [];
    if(this.openLevel != null && level <= this.openLevel){
      open = true;
      this.groupInitState[group.id] = "open";
    }

    if(this.state.allOpen == true){
      open = true;
    }


    var renderTree = [];
    group.children.forEach(child => {
      if (child.type == "group") {
        renderTree.push(this.createGroup(child,false,level+1));
      } else if (child.type == "record" || child.type === undefined) {
        renderTree.push(this.createRecord(child));
      }
    });
    return (
      <div
        className={
          first == true ? "acmTreeGroup acmTreeGroupMain" : "acmTreeGroup"
        }
        key={group.id}
      >
        <div className="acmFileLine" />
        <div className="acmTreeContentLine" />
        <div
          className="acmTreeGroupHeader"
          onMouseEnter={e => this.centerHover(e)}
          onClick={e => this.toggleGroup(e, group.id)}
        >
          <div className="acmTreeFolderIcon icon-folder" />
          {this.props.multiple == true && (
            <input
              className="acmTreeInput"
              onChange={e => this.addToSelectChange(e, group.id)}
              type="checkbox"
            />
          )}

          {group.name}
        </div>
        <div
          className={
            open === true
              ? "acmTreeGroupContent"
              : "acmTreeGroupContent acmTreeGroupContentClose"
          }
        >
          {renderTree}
        </div>
        {first == true &&
        <React.Fragment>
          <div ref={this.hover} className="acmTreeHover" />
          <div ref={this.select} className="acmTreeSelect" />
          </React.Fragment>
        }
      </div>
    );
  }
  createRecord(child) {
    return (
      <div
        className="acmTreeRecord"
        onMouseEnter={e => this.centerHover(e)}
        onClick={e => this.selectRecord(e, child.id)}
        key={"record" + child.id}
      >
        <div className="acmFileLine" />
        <div className="acmTreeContentLine" />
        <div className="acmTreeFileIcon" />
        {this.props.multiple == true && (
          <input
            className="acmTreeInput"
            onChange={e => this.addToSelectChange(e, child.id)}
            type="checkbox"
          />
        )}
        <span>{child.name}</span>
      </div>
    );
  }
  toggleGroup(e, id) {
    var state = this.state.groupState;
    var data = {};
    if (state[id] === "close" || state[id] === undefined) {
      state[id] = "open";
      var target = e.target;
      if (!target.classList.contains("acmTreeGroupHeader")) {
        target = target.closest(".acmTreeGroupHeader");
      }

      data = { groupState: state, select: target };
      if (this.props.onSelectGroup != undefined) {
        this.props.onSelectGroup(id);
      }
      if (this.props.onChange != undefined) {
        this.props.onChange(e, "group", id);
      }
    } else if (state[id] === "open") {
      if (e.target.classList.contains("acmTreeFolderIcon")) {
        state[id] = "close";
        data = { groupState: state };
        var group = e.target.closest(".acmTreeGroup");
        var groupHeight = group.clientHeight;
        const tree = this.tree.current.querySelector(".acmTreeGroupMain")
        var y =
          e.target.getBoundingClientRect().top -
          tree.getBoundingClientRect().top +
          tree.scrollTop
          ;
        var yEnd = y + groupHeight;
        var selectY = parseInt(this.select.current.style.top);
        if (selectY > y && selectY < yEnd) {
          data["select"] = null;
        }
        if (this.props.onChange != undefined) {
          this.props.onChange(e, "group", id);
        }
      } else {

        state[id] = "open";
        var target = e.target;
        if (!target.classList.contains("acmTreeGroupHeader")) {
          target = target.closest(".acmTreeGroupHeader");
        }

        data = { groupState: state, select: target };
        if (this.props.onSelectGroup != undefined) {
          this.props.onSelectGroup(id);
        }
        if (this.props.onChange != undefined) {
          this.props.onChange(e, "group", id);
        }
      }
    }
    this.setState(data);
  }
  centerHover(e) {
    var target = e.target;
    this.hover.current.style.display = "block";
    const targetTop = target.getBoundingClientRect().top;
    const tree = this.tree.current.querySelector(".acmTreeGroupMain");
    
    const treeTop = tree.getBoundingClientRect().top;
    const scrollTop = tree.scrollTop;
    this.hover.current.style.top = targetTop - treeTop + scrollTop + "px";
  }
  selectRecord(e, id) {
    var target = e.target;
    if (!target.classList.contains("acmTreeRecord")) {
      target = target.closest(".acmTreeRecord");
    }
    this.setState({ select: target });

    if (this.props.onSelectRecord != undefined) {
      this.props.onSelectRecord(id);
    }
    if (this.props.onChange != undefined) {
      this.props.onChange(e, "record", id);
    }
  }
  closeAll() {
    this.setState({
      select: null,
      groupState: {},
      selected: []
    });
  }
  openAll(){
    const childrens = this.props.data;

    const promise = new Promise((resolve,rejects) => {
      var ids = {};
      this.openAllRecursive(childrens,ids);
      resolve(ids);
    })
    promise.then((ids) => {
      this.setState({groupState:ids});
    })
  }
  openAllRecursive(groups,pole){
    groups.forEach(element => {
      pole[element.id] = "open"
      this.openAllRecursive(element.children,pole)
    });
  }
  addToSelectChange(e, id) {
    const target = e.target;
    if (target.checked) {
      var parent = e.target.closest(".acmTreeRecord");
      if (parent == null) {
        parent = e.target.closest(".acmTreeGroup");
      }
      console.log(parent);
    } else {
      console.log("ne");
    }
  }
  render() {
    const data = this.props.data;
    const tree = this.createGroup({ children: data, id: "main" }, true);
    const multiple = this.props.multiple;
    return (
      <div
        ref={this.tree}
        className={"acmTree"}
        onMouseLeave={() => {
          this.hover.current.style.display = "none";
        }}
      >
        <div className="acmTreeMenu">
          <div className="acmTreeHeader">
          {this.props.header !== undefined && this.props.header}
          </div>
          <div className="acmTreeMenuButtons">
          <div
              className="acmTreeOpenAllIcon icon-openAll"
              title="Open all"
              onClick={() => this.openAll()}
            />
            <div
              className="acmTreeCloseAllIcon icon-closeAll"
              title="Close all"
              onClick={() => this.closeAll()}
            />
          </div>
        </div>
        {tree}
      </div>
    );
  }
}
