import React from "react";
import sAction from "sAction";

export default function FileName(props) {
  var lineRender = null;
  lineRender = <a title={props.value} href={"#detail/" + props.module + "/" + props.id}>{props.value}</a>;

  var className = "newListViewLine";
  if(props.extraClass !== undefined){
      className += " "+props.extraClass
  }

  let showImage = <div className="icon-eye inactive notAllowedClass">&nbsp;</div>;
  if (/\.(bmp|jpg|jpeg|png|gif|svg|webp|ico)$/i.test(props.value)) {
    if (props.module === "Notes"){
      showImage = <div className="icon-eye pointerClass" onClick={() => sAction.openRightPanel("image", { name: props.value, url: sAction.param.files + "notes_image/" + props.data.id })}>&nbsp;</div>;
    } else {
      showImage = <div className="icon-eye pointerClass" onClick={() => sAction.openRightPanel("image", { name: props.value, url: sAction.param.files + "image/"+ props.id})}>&nbsp;</div>;
    }
  }

  let download = <div onClick={(e) => { props.onGetFile({ id: props.id, module: props.module }) }} className="icon-download listDownLoadIcon"></div>;
  if (props.module === "Notes" && props.value === "____"){
    download = <div className="icon-download listDownLoadIcon inactive notAllowedClass"></div>;
  }

  return (
    <div className={className}>{download}{showImage}{lineRender}</div>
  );
}
