import React from "react";
import PureComponent from "../../pure";
import sAction from 'sAction';

export default class CustomLinesTotal extends PureComponent {
    constructor(props) {
        super(props);

    }

    getField(col, key) {
        const lines = this.props.lines;
        const moduleData = this.props.moduleData;
        var sum = 0;
        if (lines) {
            lines.forEach((line) => {
                if ((line.get(col) || line.get(col) === 0) && line.get('deleted') != 1) {
                    sum = sum + parseFloat(line.get(col));
                }
            });
        }
        var className = 'customLinesCell'

        var value = Math.round(sum * 100) / 100;
        if (moduleData.get(col).get('type') === 'currency') {
            className += ' alignRight';
            value = parseFloat(value).toFixed(2);
        }
        var style = null;
        const def = this.props.def;
        if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
            style = def.get('style').get(col).toJS();
        }

        return (
            <div className={className} key={key} style={style}>{value}</div>
        );
    }

    getEmptyCell(col, key, className = '') {
        var style = null;
        const def = this.props.def;
        if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
            style = def.get('style').get(col).toJS();
        }
        return (<div className={'customLinesCell ' + className} key={key} style={style}></div>);
    }

    getLineButtons() {
        var minWidth = 0;
        if (sAction.hasAccess(this.props.info.get('module'), "detail")) {
            if (this.props.info.get('eyeIcon')) {
                minWidth += 14;
            }
            if (this.props.info.get('detailButton')) {
                minWidth += 14;
            }
        }
        if (sAction.hasAccess(this.props.info.get('module'), "edit")) {
            if (this.props.info.get('orderRow') && this.props.info.get('ordering') === 'manual') {
                minWidth += 14;
            }
            minWidth += 16;
        }

        return (
            <div className='customLinesCell customLinesCellDelete' key={'buttons'} style={{minWidth: minWidth + 'px',}}>
            </div>
        );
    }

    render() {
        const def = this.props.def;

        var cols = [];
        const totalCols = def.get('total').toJS();
        let style = null;
        def.get('fields').toJS().forEachObject((col, key) => {
            if (cols.length == 0) {
                if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
                    style = def.get('style').get(col).toJS();
                }
            }
            if (totalCols.includes(col)) {
                cols.push(this.getField(col, key));
            } else {
                cols.push(this.getEmptyCell(col, key));
            }
        });
        cols[0] = (<div className='customLinesCell' key={0} style={style}>{sAction.translate('LBL_TOTAL')}</div>);
        const lineButtons = this.getLineButtons();

        return (
            <div className='customLinesRowContainer customLinesFooter'>
                {lineButtons}
                {cols}
            </div>
        );
    }
}
