export default function calcSums(way, params){
    var dph = params.data.dph;

    if(params.type == "group"){
        return calcGroupSums(params.data, this, dph);
    }

    var data = params.data;

    var total_cost_price = 0;
    var total_list_price = 0;
    var total_discount_amount = 0;
    var total_discount_price = 0;
    var total_tax = 0;
    var total_margin = 0;
    let total_price_after_discount = 0;
    let total_price_after_add_discount = 0;

    var self = this;

    data.groups.forEach(function(group){
        let pom = calcGroupSums(group, self, dph);
        total_cost_price += pom.total_cost_price;
        total_list_price += pom.total_list_price;
        total_discount_amount += pom.total_discount_amount;
        total_discount_price += pom.total_discount_price;
        total_tax += pom.total_tax;
        total_margin += pom.total_margin;
        total_price_after_discount += pom.total_price_after_discount;
        total_price_after_add_discount += pom.total_price_after_add_discount;
    });

    return {
        total_cost_price : total_cost_price,
        total_list_price : total_list_price,
        total_discount_amount : total_discount_amount,
        total_discount_price : total_discount_price,
        total_discount: total_discount_price,
        total_tax : total_tax,
        total_margin : total_margin,
        total: total_discount_price + self.parseNumber(data.shipping) + total_tax,
        total_price_after_discount: total_price_after_discount,
        total_price_after_add_discount: total_price_after_add_discount,
    };
}

function calcGroupSums(data, sAction, dph){
    var total_cost_price = 0;
    var total_list_price = 0;
    var total_discount_amount = 0;
    var total_discount_price = 0;
    var total_tax = 0;
    var total_margin = 0;
    let total_price_after_discount = 0;
    let total_price_after_add_discount = 0;


    data.lines.forEach(function(prod){
        if(prod.opce_c){
            return;
        }

        let line_discount_price = sAction.parseNumber(prod.list_price);

        total_cost_price += sAction.parseNumber(prod.cost_price);
        total_list_price += line_discount_price;
        total_price_after_discount += sAction.parseNumber(prod.price_after_discount);

        let discount = sAction.parseNumber(prod.discount_amount);
        let priceListDiscount = sAction.parseNumber(prod.price_after_add_discount)
        let valAmount = 0;
        if(prod.discount_select == 1){
            discount = (sAction.parseNumber(prod.price_after_discount)/100)*discount;
            priceListDiscount = (sAction.parseNumber(prod.list_price)/100)*priceListDiscount;
        }

        total_price_after_add_discount += priceListDiscount

        if(prod.selected_mj === "mj2"){
            valAmount = ((sAction.parseNumber(prod.list_price) - priceListDiscount) * prod.price_mj2) * prod.quantity;
        } else {
            valAmount = (sAction.parseNumber(prod.list_price) - priceListDiscount) * prod.quantity;
        }

        total_discount_price += valAmount
        total_discount_amount += discount;

        if(prod.tax_class != "Non-Taxable"){
            total_tax += (valAmount / 100) * dph;
        }

    });

    total_margin = total_discount_price - total_cost_price;

    return {
        total_cost_price : total_cost_price,
        total_list_price : total_list_price,
        total_discount_amount : total_discount_amount,
        total_discount_price : total_discount_price,
        total_tax : total_tax,
        total_margin : total_margin,
        total_price_after_discount : total_price_after_discount,
        total_price_after_add_discount : total_price_after_add_discount,
    };
}
