import React, { Component } from "react";
import PureComponent from "../pure";

import HTMLText from "../formElements/HTMLText";
import ProductComponent from "./Quotes/ProductComponent";
import OppProductComponent from "./Opportunities/OppProductComponent";

import InviteesContainer from "../popup/calendarWindow/inviteesContainer";
import ACLRoles from "./ACLRoles/ACLRoles";

import  sAction  from "sAction";

const RichEditor = React.forwardRef((props, ref) => <HTMLText ref={ref} {...props} />);

class DetailCustomComponent extends PureComponent {
    constructor(props) {
        super(props );
        this.textRef = React.createRef();
    }

    getEditorContent = () => {
        return tinyMCE.activeEditor.getContent();
    };

    saveText = () => {
        sAction.dsClear();
        sAction.dsAdd("set", this.props.prefix +"/fields/article/value", this.getEditorContent());
        sAction.dsAdd("set", this.props.prefix +"/changes/fields/article", this.getEditorContent());
        sAction.dsProcess();
    };

  render() {
    const module = this.props.module;
    const way = this.props.way;
    const prefix = this.props.prefix;
    const view = this.props.view;
    const data = this.props.data;
    const readonly = this.props.readonly;

        let renderComp = null;

        if(view == "calendar"){
            return null;
        }
       switch (module){
          case "Meetings":
          case "Calls":
              renderComp = <div className="detailInvComp detailInvCustComp">
                  <InviteesContainer data={data.toJS().invitees} prefixComponent={way + "/invitees"} prefix={prefix} detailWay={prefix} pomClass="detailView" isPopup={false} />
                  </div>;
              break;
            case "Quotes":
                const cenotvorba = sAction.dataGet(prefix + "/fields/cenotvorba/value");
                const accountDiscount = sAction.dataGet(`${this.props.way.split("/")[0]}/fields/account_discount/value`)
                renderComp = <ProductComponent data={data.get("productData")} accountDiscount={accountDiscount}
                                               cenotvorba={cenotvorba} way={way + "/productData"} readonly={readonly}/>
                break;
            case "Opportunities":
                renderComp = <OppProductComponent data={data.get("productData")} way={way+"/productData"} readonly={readonly}/>
                break;
          case "ACLRoles":
                if (data.get("recordId")) {
                  renderComp = <ACLRoles way={way} data={data}/>
                }
                break;
            default:
                if(data.get("textEditor")){
                    renderComp =
                        <div className={"detailViewTabContentRow"} style={{maxHeight: "500px"}}>
                            <RichEditor
                                ref={this.textRef}
                                defaultValue={data.get("textEditor").get("value")}
                                onChange={this.saveText}
                                key={window.location.hash}
                            />
                        </div>

                }
        }

    return renderComp;
  }
}
export default DetailCustomComponent;
