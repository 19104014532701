

export default function popup(content,data = null){
  document.activeElement.blur();
  const id = this.getNewPopupId();

  this.dsClear();
  this.dsAdd("set","conf/popup"+id+"/show",true)
  this.dsAdd("set","conf/popup"+id+"/content",content)
  this.dsAdd("set","conf/popup"+id+"/data",{});
  if(data != null){
    data.forEachObject((value,key) => {
      this.dsAdd("set","conf/popup"+id+"/data/"+key,value);
    })
  }
  this.dsProcess();
}
