import React from "react";
import PureComponent from "../../pure";
import sAction from 'sAction';

export default class CustomLinesHeader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { cols: [] };
    }

    getField(col, key) {
        const moduleData = this.props.moduleData;
        const info = this.props.info;
        if(!moduleData.get(col)){
            console.error("Missing col", col)
        }
        const required = moduleData.get && moduleData.get(col).get && moduleData.get(col).get('required') ? (<span className='required'>*</span>) : null;
        var style = null;
        const def = this.props.def;
        if (def.get && def.get('style') && def.get('style').get && def.get('style').get(col)) {
            style = def.get('style').get(col).toJS();
        }

        //Vzhledem k tomu ze potreba mit na detailu jiny nazev nez na radku, musime zde mit tuto vyjimku
        if(moduleData.get(col).get('vname') === "LBL_DO_NOT_DISCOUNT"){
            return <div title={sAction.translate(moduleData.get(col).get('vname'), info.get('module'))} className='customLinesCell' key={key} style={style}>NSD</div>
        }

        return (
            <div title={sAction.translate(moduleData.get(col).get('vname'), info.get('module'))} className='customLinesCell' key={key} style={style}>{sAction.translate(moduleData.get(col).get('vname'), info.get('module'))}{required}</div>
        );
    }

    getLineButtons() {
        var minWidth = 0;
        if (sAction.hasAccess(this.props.info.get('module'), "detail")) {
            if (this.props.info.get('eyeIcon')) {
                minWidth += 14;
            }
            if (this.props.info.get('detailButton')) {
                minWidth += 14;
            }
        }
        if (sAction.hasAccess(this.props.info.get('module'), "edit")) {
            if (this.props.info.get('orderRow') && this.props.info.get('ordering') === 'manual') {
                minWidth += 14;
            }
            minWidth += 16;
        }

        return (
            <div className='customLinesCell customLinesCellDelete' key={'buttons'} style={{minWidth: minWidth + 'px',}}>
            </div>
        );
    }

    render() {
        const def = this.props.def;

        var cols = [];
        def.get('fields').toJS().forEachObject((col, key) => {
            cols.push(this.getField(col, key));
        });
        const lineButtons = this.getLineButtons();

        return (
            <div className='customLinesRowContainer customLinesHeader'>
                {lineButtons}
                {cols}
            </div>
        );
    }
}
