import React from "react";
import PureComponent from "../pure";
import sAction from "sAction"
import MaterialPopper from '@material-ui/core/Popover';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

export default class Popper extends PureComponent {

    close(index){

        sAction.dataDelete("conf/popper",index)
    }
    render() {
        const data = this.props.data;
        var poppersRender = [];
        data.forEach((popper, index) => {
            const anchorOrigin = popper.get('anchorOrigin') !== undefined ? popper.get('anchorOrigin').toJS() : null;
            const transformOrigin = popper.get('transformOrigin') !== undefined ? popper.get('transformOrigin').toJS() : null;
            const className = popper.get('className') ? popper.get('className') : '';
            const selector = popper.get("selector");
            const el = document.querySelector(selector);
            var header = popper.get("header") ?? ""
            header = header.replace(":","");
            if (el != null) {
                poppersRender.push(
                    <MaterialPopper
                    className={"acmPopover " + className}
                    anchorEl={el}
                    {...anchorOrigin && {anchorOrigin : anchorOrigin}}
                    {...transformOrigin && {transformOrigin: transformOrigin}}
                    open={data.get("open") ?? true}
                    key={index}
                    onEscapeKeyDown={() => this.close(index)}
                >
                        <ClickAwayListener onClickAway={() => this.close(index)}>
                            <div>
                            <div className="popperHeader">
                                <label dangerouslySetInnerHTML={{ __html: header }} />
                            </div>

                            <div className="popperContent">
                                {popper.get("content")}
                            </div>
                            </div>
                        </ClickAwayListener>
                    </MaterialPopper>
                    )
            }
        })
        return (
            poppersRender
        )
    }
}