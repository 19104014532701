export default async function calGetInitData(paramData, callback) {
    var self = this

    const callCronRest = await this.rest.fetchData("calGetInitData", "POST", paramData).catch((data) => {
        console.error(data)
    });

    let data = callCronRest.data

    self.calEvents.sharedView = data.params?.sharedView
    self.calEvents.sharedIds = data.params?.sharedIds

    var params = data.params

    if (params != undefined) {
        self.dsClear()
        action = {
            type: "CALENDARINIT",
            content: { params: params },
            prefix: paramData.prefix
        }
        self.dsAddCustom(action)
        self.dsProcess()
    }

    var eventData = data.eventData

    var views = [{
        data: data.views.Meetings,
        prefix: paramData.prefix + "/meetingView",
        module: "Meetings"
    },
    {
        data: data.views.Calls,
        prefix: paramData.prefix + "/callView",
        module: "Calls"
    },
    {
        data: data.views.Tasks,
        prefix: paramData.prefix + "/taskView",
        module: "Tasks"
    }]

    self.dsClear()

    views.forEach(view => {

        let content = {
            id: null,
            actionButtons: [],
            module: view.module,
            subpanels: []
        }
        self.detailContent(view.data, content)

        content.subpanels = []

        let action = {
            type: "PAGELOADDETAIL",
            content: content,
            prefix: view.prefix,
            function: self
        }
        self.dsAddCustom(action)

    })

    let action = {
        type: "CALENDARLOADQUICKFORM",
        content: {
            invitees: {
                Users: [],
                Contacts: [],
                Leads: []
            }
        },
        prefix: paramData.prefix + "/invitees",
        prefixBackup: paramData.prefix + "/inviteesBackup",
    }
    self.dsAddCustom(action)
    self.dsProcess()
    if (!self.calEvents) {
        self.calEvents = []
    }
    self.calEvents.calendars = []

    eventData.calendars.forEach((cal) => {

        // default je tu, aby se vyresetovalo pro sdileny pohled
        let pomEvents = {
            "Meetings": [],
            "Calls": [],
            "Tasks": []
        }

        cal.Meetings.forEach((act) => {
            pomEvents.Meetings.push(act)
        })

        cal.Calls.forEach((act) => {
            pomEvents.Calls.push(act)
        })

        cal.Tasks.forEach((act) => {
            pomEvents.Tasks.push(act)
        })

        self.calEvents.calendars[cal.id] = {
            id: cal.id,
            events: pomEvents
        }
    })

    callback(self, false)
}
