import detailDefault from "../../detailDefault"

export default class detailacm_orders extends detailDefault {
    load(sAction, data) {
    }

    update(sAction, data) {
    }

    updateDetailLine(sAction, data) {
        switch (data.field) {
            case 'name':
                if (data.value.id) {
                    sAction.load();
                    let fields = {product_list_price: 'list_price', product_code: 'mft_part_num'};
                    const searchData = {
                        module: 'ProductTemplates',
                        fields: ['id'],
                        getFields: Object.values(fields),
                        value: data.value.id,
                        limit: 999999,
                    };

                    sAction.quickSearch(searchData, returnData => {
                        sAction.dsClear();
                        fields.forEachObject((templateName, lineName) => {
                            data.saveField(returnData[0][templateName], lineName, true);
                        });
                        sAction.dsProcess();

                    });
                    sAction.unLoad()
                }
                break;
        }
    }
}
