
import Float from '../Float/Float'
import  sAction  from "sAction";

export default class TimeFloat extends Float {

  saveField(data) {
    if (data.value.includes(':')){

      let splitted = data.value.split(":");

      let hours = parseFloat(splitted[0]);
      let minutes = parseFloat(splitted[1]) / 60;
      let seconds = parseFloat(splitted[2]) / 60 / 60;

      let sum = hours;
      if (minutes > 0)
        sum += minutes;
      if (seconds > 0)
        sum += seconds;

      data.value = parseFloat(sum);
    }

    super.saveField(data);
  }
  
}
