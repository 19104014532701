import React, { PureComponent } from "react";
import InviteesContainer from "../popup/calendarWindow/inviteesContainer";
import PropTypes from 'prop-types';

class DetailInvTabComponent extends PureComponent {

    render() {
        const active = this.props.active;
        const prefix = this.props.prefix;
        const way = this.props.way;

        let data = null;
        if (this.props.data != null) {
            data = this.props.data.toJS();
        }

        let pomClass = "detailViewTabContentBlok detailInvComp";

        if (active) {
            pomClass += " select";
        }

        return (
            <div className={pomClass}>
                {data &&
                    <InviteesContainer isPopup={true} data={data.invitees} prefixComponent={way + "/invitees"} prefix={prefix} pomClass="detailView" detailWay={this.props.detailWay} />
                }
            </div>
        );
    }

}
export default DetailInvTabComponent;

DetailInvTabComponent.propTypes = {
    active: PropTypes.bool,
    prefix: PropTypes.string.isRequired,
    way: PropTypes.string.isRequired,
    module: PropTypes.string.isRequired,
    detailWay: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
};
