export default function currencyConvert(val, oldCur, newCur, convRate = null){
    // console.log("currencyConvert", this);

    if (!oldCur) {
        oldCur = "-99";
    }

    if(oldCur == newCur || !oldCur || !newCur){
        return val;
    }
    
    let newVal = 0;

    if(oldCur == "-99"){
        const conv = convRate ? 1 / convRate : this.currencies[newCur]?.conversion_rate;
        newVal = val * conv;
    } else if(newCur == "-99"){
        const conv = convRate ? 1 / convRate : this.currencies[oldCur]?.conversion_rate;
        newVal = val / conv;
    } else {
        const oldConv = this.currencies[oldCur].conversion_rate;
        const newConv = convRate ? 1 / convRate : this.currencies[newCur]?.conversion_rate;

        newVal = (val / oldConv) * newConv;
    }

    const newValRounded = newVal.toFixed(this?.userPreferences?.currency_significant_digits || 2);

    return newValRounded;

}
