import detailDefault from "../../detailDefault"

export default class detailacm_orders extends detailDefault {
    load(sAction, data) {
    }

    update(sAction, data) {
        if(data.field === "acm_orders_accountsaccounts_ida"){
            const linesInfo = {
                accountId: data.value.id,
                lines: sAction.dataGet(`${data.prefix}/customData/customLines/lines`),
                module: data.module
            }

            sAction.load()
            //Kdyz se zmeni firma chceme prepocicat vsechny ceny na radcich
            sAction.rest.post("reloadPriceListsCustomLines", linesInfo, returnData => {
                if(returnData.status && returnData?.message?.data?.length) {
                    sAction.dataSet(`${data.prefix}/customData/customLines/lines`, returnData.message.data)
                }

                sAction.unLoad();
            }, false)
        }
    }

    updateDetailLine(sAction, data) {
        switch (data.field) {
            case 'name':
                if (data.value.id) {
                    sAction.load();
                    let fields = {cena_bez_dph_kus: 'list_price', kod: 'mft_part_num'};
                    const searchData = {
                        module: 'ProductTemplates',
                        fields: ['id'],
                        getFields: Object.values(fields),
                        value: data.value.id,
                        limit: 999999,
                    };

                    sAction.quickSearch(searchData, returnData => {
                        sAction.dsClear();
                        fields.forEachObject((templateName, lineName) => {
                            data.saveField(returnData[0][templateName], lineName, true);
                        });
                        sAction.dsProcess();

                        //Potrebujeme se dotazat zda produkt neni v ceniku pokud se najde nastavi se cena z ceniku do cena_bez_dph_kus
                        const linesInfo = {
                            accountId: sAction.dataGet(`${data.prefix.split('/')[0]}/fields/acm_orders_accounts_name/def/id_value`),
                            line: sAction.dataGet(data.prefix),
                            module: data.module
                        }

                        sAction.rest.post("loadPriceListsCustomLines", linesInfo, returnData => {
                            sAction.load()
                            if(returnData.status && returnData.message?.data?.cena_bez_dph_kus){
                                data.saveField(returnData.message.data.cena_bez_dph_kus, "cena_bez_dph_kus", true);
                            }

                            sAction.unLoad();
                        }, false)

                    });
                }
                break;
        }
    }
}
