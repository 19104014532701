import React from "react";
import PureComponent from "../pure";
import ImportFileHeader from "./ImportFileHeader";
import ImportFileRow from "./ImportFileRow";

class ImportFileTable extends PureComponent {
    constructor(props) {
        super(props);
    }

    createRows = (data, rows, columnCount, additionalData) => {
        let i = 0;
        for (i; i < data.size; i++) {
            const rowData = data.get(i.toString());
            rows.push(
                <ImportFileRow key={i} data={rowData} columnCount={columnCount} additionalData={additionalData}/>
            )
        }
    }

    render() {
        const data = this.props.data;
        const prefix = this.props.way;
        const columnCount = data.get('columnCount');
        const additionalData = data.get('mapping')?.get?.('additionalData');
        let header;
        let rows = [];
        if (data !== undefined)
        {
            header =  <ImportFileHeader way={prefix} data={data} additionalData={additionalData} options={this.props.options}/>;
            this.createRows(data.get('import'), rows, columnCount, additionalData);
        }

        return(
            <div className="importTableWrapper">
                <table className="importMappingTable">
                    <thead className="importMappingTableHeader">
                    {header}
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ImportFileTable;
