import React from "react";
import PureComponent from "../../pure";

import Select from '../../formElements/Select';
import InputText from "../../formElements/InputText";
import Button from "../../formElements/Button";
import TickBox from '../../formElements/TickBox'
import Radio from "../../formElements/Radio";

import  sAction  from "sAction";

export default class HomeWidgetEditCalendar extends React.Component{
    constructor(props){
        super(props);
        
        const data = sAction.dataGet(props.way+"/data/options").toJS();

        this.state = {
          widgetAutoSize : data.widgetAutoSize == undefined ? true : data.widgetAutoSize,
          widgetSize : data.widgetSize == undefined ? 450 : data.widgetSize,
          name : props.name,
          showMeetings : data.showMeetings,
          meetStates: data.meetStates,
          showCalls : data.showCalls,
          callStates: data.callStates,
          showTasks: data.showTasks,
          taskStates: data.taskStates,
          weekNumbers: data.weekNumbers,
          showCompleted: data.showCompleted,
          showWeekends: data.showWeekends,
          initView: data.initView,
          sharedView: data.sharedView,
          sharedIds: data.sharedIds,
          startIntervalHours : data.startIntervalHours,
          startIntervalMinutes : data.startIntervalMinutes,
          endIntervalHours : data.endIntervalHours,
          endIntervalMinutes : data.endIntervalMinutes,
          users : data.users,
          user_id : data.user_id
        }
      }
  
      updateStateFilters(field, key, val){
        let pomStates = this.state[field];
        if(val){
          pomStates.push(key);
        }
        else{
          let pomInd = pomStates.indexOf(key);
          pomStates.splice(pomInd, 1);
        }
        this.updateFilters(field, pomStates);
      }

      updateFilters(name, value){
        let pom = {};
        pom[name] = value;
        this.setState(pom);
    }

    saveSettings(){

        var statePom = this.state;

        if(!this.state.widgetAutoSize){
            statePom.height = this.state.widgetSize + 'px';
        }

        sAction.dataSet(this.props.way+"/data/options", statePom);
        sAction.dataSet(this.props.way+"/name", this.state.name);
        sAction.popupHide();
        //TTTT pridat callback, ale zacni nekde jinde...

        sAction.homeLayoutSave(this.saveSettingsCallback.bind(this, sAction));
    }

    saveSettingsCallback(sAction){
        sAction.homeWidgetRefresh("calendar", this.props.way);
    }

    handleSizeChange(name, value){
        console.log("handle change");
    }

    handleRadioVal(){
        console.log("handle Radio");
    }

    render(){
        var viewsOptions = [];
        sAction.app_strings.calendar_initial_view.forEachObject((lbl, key) => {
            viewsOptions.push({value: key, label: lbl});
        });

        var meetOptions = [];
        sAction.app_strings.meeting_status_dom.forEachObject((lbl, key) => {
            meetOptions.push({value: key, label: lbl});
        });

        var callOptions = [];
        sAction.app_strings.call_status_dom.forEachObject((lbl, key) => {
            callOptions.push({value: key, label: lbl});
        });

        var taskOptions = [];
        sAction.app_strings.task_status_dom.forEachObject((lbl, key) => {
            taskOptions.push({value: key, label: lbl});
        });

        var userOptions = [];
        this.state.users.forEachObject((name, key) => {
            userOptions.push({value: key, label: name});
        });


        var pomMeetRender = [];
        meetOptions.forEachObject(opt => {
            let selVal = false;
            let itemClass = " notSelected";
            if(this.state.taskStates != null && this.state.meetStates.indexOf(opt.value) >= 0){
            selVal = true;
            itemClass = " selected";
            }
            pomMeetRender.push(<div key={"meetStates_"+opt.value} className="calFiltersRow">
                                <TickBox className="calFiltersStateTick" checked={!!selVal} onChange={(value) => this.updateStateFilters("meetStates", opt.value, value)} />
                                <span className={"calFilterLbl"+itemClass}>{opt.label}</span>
            </div>)
        });

        var pomCallRender = [];
        callOptions.forEachObject(opt => {
            let selVal = false;
            let itemClass = " notSelected";
            if(this.state.callStates != null && this.state.callStates.indexOf(opt.value) >= 0){
            selVal = true;
            itemClass = " selected";
            }
            pomCallRender.push(<div key={"callStates_"+opt.value} className="calFiltersRow">
                                <TickBox className="calFiltersStateTick" checked={!!selVal} onChange={(value) => this.updateStateFilters("callStates", opt.value, value)} />
                                <span className={"calFilterLbl"+itemClass}>{opt.label}</span>
            </div>)
        });

        var pomTaskRender = [];
        taskOptions.forEachObject(opt => {
            let selVal = false;
            let itemClass = " notSelected";
            if(this.state.taskStates != null && this.state.taskStates.indexOf(opt.value) >= 0){
            selVal = true;
            itemClass = " selected";
            }
            pomTaskRender.push(<div key={"taskStates_"+opt.value} className="calFiltersRow">
                                <TickBox className="calFiltersStateTick" checked={!!selVal} onChange={(value) => this.updateStateFilters("taskStates", opt.value, value)} />
                                <span className={"calFilterLbl"+itemClass}>{opt.label}</span>
            </div>)
        });

        var unitCell = null;
        if(!this.state.widgetAutoSize){
            unitCell =  <React.Fragment>
                            <input
                                className="form-control form-control-sm datetimehours calTimeInput"
                                type="number"
                                min={200}
                                max={3000}
                                step="10"
                                value={this.state.widgetSize}
                                onChange={e => this.updateFilters("widgetSize", e.target.value)}
                                name="widgetSize"
                            />{" px"}
            </React.Fragment>;
        }

        return(
            <React.Fragment>
                <div className="acmPopupHeader">
                {sAction.translate("LBL_EDIT_WIDGET", "Home")}
                </div>
                <div className="widgetAcmPopupContent">
                    <div className="widgetAcmPopupContentTableContainer">
                    <table>
                        <tbody>
                        <tr>
                            <td className="calSetLbl">
                                {sAction.translate("LBL_ENTER_WIDGET_SIZE", "Home")}:    
                            </td>
                            <td className="radioCell">
                            <Radio
                                tabIndex={0}
                                onChange={checked => this.updateFilters("widgetAutoSize", true)}
                                checked={this.state.widgetAutoSize}
                                />
                                {" " + sAction.translate("LBL_AUTO_SIZE", "Home")}
                            </td>
                            <td></td> 
                            <td className="radioCell">
                            <Radio
                                tabIndex={0}
                                onChange={checked => this.updateFilters("widgetAutoSize", false)}
                                checked={!this.state.widgetAutoSize}
                            />
                            {" " + sAction.translate("LBL_OWN_SIZE", "Home")}
                            </td>
                            <td>
                                {unitCell}                           
                            </td>
                        </tr>
                        <tr>
                            <td className="calSetLbl">{sAction.translate("LBL_ENTER_NAME", "Home")}:</td>
                            <td>
                            <InputText
                                id="name"
                                type="text"
                                defaultValue={this.props.name}
                                fullWidth
                                onBlur={(ev) => this.updateFilters("name", ev.target.value)}
                            />
                            </td>
                            <td className="calSetFiller">
                            </td>
                            <td className="calSetLbl">{sAction.translate("LBL_ASSIGNED_TO_NAME", "Meetings")}</td>
                            <td>
                                <Select open={false} name="user_id" options={userOptions} defaultValue={this.state.user_id} onChange={(ev) => this.updateFilters("user_id", ev.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_INITIAL_VIEW", "Calendar")}:</td>
                            <td>
                                <Select open={false} name="initView" options={viewsOptions} defaultValue={this.state.initView} onChange={(ev) => this.updateFilters("initView", ev.target.value)} />
                            </td>
                            <td></td>
                            <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_WEEKEND_SHOW", "Calendar")}: </td>
                            <td>
                                <TickBox className="calFiltersStateTick" name="showWeekends" checked={!!this.state.showWeekends} onChange={(value) => this.updateFilters("showWeekends", value)} />
                            </td>
                        </tr>
                        <tr>
                            <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_COMPLETED_SHOW", "Calendar")}: </td>
                            <td><TickBox className="calFiltersStateTick" name="showCompleted" checked={!!this.state.showCompleted} onChange={(value) => this.updateFilters("showCompleted", value)} /></td>
                            <td></td>
                            <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_MEETINGS_SHOW", "Calendar")}: </td>
                            <td><TickBox className="calFiltersStateTick" name="showMeetings" checked={!!this.state.showMeetings} onChange={(value) => this.updateFilters("showMeetings", value)} /></td>
                        </tr>
                        <tr className="lastSectionRow">
                            <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_CALLS_SHOW", "Calendar")}: </td>
                            <td><TickBox className="calFiltersStateTick" name="showCalls" checked={!!this.state.showCalls} onChange={(value) => this.updateFilters("showCalls", value)} /></td>
                            <td></td>
                            <td className="calSetLbl">{sAction.translate("LBL_SETTINGS_TASKS_SHOW", "Calendar")}: </td>
                            <td><TickBox className="calFiltersStateTick" name="showTasks" checked={!!this.state.showTasks} onChange={(value) => this.updateFilters("showTasks", value)} /></td>
                        </tr>
                        <tr>
                            <td className="calSetLbl calSetStateLbl">{sAction.translate("LBL_MEETING_STATUS", "Calendar")}: </td>
                            <td className="calSetStateContainer">{pomMeetRender}</td>
                            <td></td>
                            <td className="calSetLbl calSetStateLbl">{sAction.translate("LBL_CALL_STATUS", "Calendar")}: </td>
                            <td className="calSetStateContainer">{pomCallRender}</td>
                        </tr>
                        <tr className="lastSectionRow">
                            <td className="calSetLbl calSetStateLbl">{sAction.translate("LBL_TASK_STATUS", "Calendar")}:</td>
                            <td className="calSetStateContainer">{pomTaskRender}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="acmPopupButtons">
                    <Button className="calendarButton acmButton hoverGreen" onClick={() => {this.saveSettings()/*sAction.saveCalSettings(this.state, this.saveCalSettingCallBack)*/}}>
                        <div className={"calHeaderIcon icon-saveIcon"} ></div>
                        {sAction.translate("LBL_SAVE", "Home")}
                    </Button>  
                    <Button className="calendarButton acmButton hoverRed" onClick={() => {sAction.popupHide()}}>
                        <div className={"calHeaderIcon icon-deleteIcon"} ></div>
                        {sAction.translate("LBL_CANCEL", "Home")}
                    </Button>  
                </div>
            </React.Fragment>
        );
    }
}
