import React from "react";

import InputField from "../inputFieldClass";
import InputText from "../../formElements/InputText";
import  sAction  from "sAction";
import TickBox from "../../formElements/TickBox";
import Radio from "../../formElements/Radio";

import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from "../../formElements/Autocomplete";
export default class EmailInputField extends InputField {
    constructor() {
        super();
        this.state = {
            state: "fine",
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            resultOpen: false,
            searchText: "",
            error: false,
        };
        this.input = React.createRef();
        this.autocomplete = React.createRef();
    }

    onChange = (e) => {
        let i = e.target.value;
        let val = e.target.checked ? "1" : "0";
        const way = this.props.way; // view/fields/email1
        sAction.dsClear();

        switch (e.target.name) {
            //odhlaseny
            case "OptOutFlag":
                sAction.dsAdd("set", way + "/def/emails/" + i + "/opt_out", val);
                break;

            //nevalidni
            case "InvalidFlag":
                sAction.dsAdd("set", way + "/def/emails/" + i + "/invalid_email", val);
                break;

            //primarni
            case "PrimaryFlag":
                const v = parseInt(i, 10);
                const count = this.props.data.def.get("emails").size;

                for (let j = 0; j < count; j++) {
                    const val = j === v ? "1" : "0";
                    sAction.dsAdd("set", way + "/def/emails/" + j + "/primary_address", val);
                }
                break;

            default:
                // meni se email
                sAction.dsAdd("set", way + "/def/emails/" + e.target.name + "/email_address", i);
                sAction.dsAdd("set", way + "/def/emails/" + e.target.name + "/error", this.checkError(i));

                break;
        }

        sAction.dsProcess();

        const canSave = this.indicateChange();

        return canSave;
    };

    indicateChange = () => {
        const canSave = !this.checkErrors();

        sAction.dsClear();
        sAction.dsAdd("set", this.props.prefix + "/changes/fields/email1", true);
        sAction.dsAdd("set", this.props.way + "/value", canSave || null); //aby to (ne)proslo pres required field
        sAction.dsAdd("set", this.props.prefix + "/canSave", canSave);
        sAction.dsAdd("set", this.props.way + "/def/edit", true);
        sAction.dsProcess();

        return canSave;
    };

    keyUpCall(e) {
        const keyCode = e.keyCode;

        if(keyCode === 9){
            this.storeEmail(null);  // uloz aktualni email
            this.onKeyDown(e,"last");   // skoc mimo
            return;
        }

        if(!this.input?.current?.value){
            this.setState({resultOpen: false});
        } else {
            this.setState({searchText: this.input.current.value, resultOpen: true});
        }

        if(keyCode === 27 && this.state.resultOpen == true){
            this.save();
            return;
        }

        if(this.autocomplete?.current){

            if (keyCode == 13 && e.ctrlKey === false && this.state.resultOpen == true){
                this.autocomplete.current.keyUpHandler(keyCode);
                return
            }
            if(keyCode === 13 ||keyCode == 27){
                this.save();    // uzavre vse a muze se pokracovat na dalsi poluzku na detailu
                return;
            }
            this.autocomplete.current.keyUpHandler(keyCode);
        }
    }

    storeEmail = (item) => {
        const emailKey = this.props.emailKey;
        let email;

        // kdy muze byt undefined:
        // zadal jsesm jmeno rucne (nevybiral jsem ze seznamu)
        if(item !== null && item.name){
            this.input.current.value = item.name;
            email = item.name;
        } else {
            email =  this.input.current.value;
        }

        let error = this.checkError(email)  // regex controla
        if(error) {
            this.setState({error: error, resultOpen: false})
            return;
        }

        let existingEmails = sAction.dataGet(this.props.way + "/def/emails");
        let count = 0;
        existingEmails.forEach((storedEmail,index) => {
            if(storedEmail.get("email_address") === email && index !== this.props.emailKey) {
                count++;
            }
        });
        if (count > 0) {
            // Tento e-mail je již na seznamu.
            error = sAction.translate("ERR_EMAIL_ALREADY_ON_LIST", "Emails")
            this.setState({error: error, resultOpen: false})
        } else {
            sAction.dsClear();
            sAction.dsAdd("set",this.props.way +"/def/emails/"+emailKey+"/email_address",email)
            sAction.dsAdd("set", this.props.way + "/def/emails/" + emailKey+ "/error", error);
            sAction.dsProcess();
            this.setState({error: false, resultOpen: false})
            this.save();
        }
    }

    save = () => {
        const canSave = this.indicateChange();
        if(canSave){
            sAction.dataSet(this.props.way + "/actEdit", false);
        }
    };

    checkError = (email) => {
        if (!email) return false;
        let isValid = this.state.reg.test(email);
        return isValid ? false : sAction.translate("LBL_WRONG_VALUE");
    };

    /**
     * Je v emailech chyba?
     * @returns {boolean} vraci true, pokud byla nalezena chyba
     */
    checkErrors = () => {
        const emails = sAction.dataGet(this.props.way + "/def/emails");
        const isRequiredField = this.props.data.def.get("required");
        const existingEmails = [];
        let isError = false;

        if (emails && emails.size) {
            let i = -1;
            for (let email of emails) {
                i++;
                email = email.get("email_address");
                if (email) {
                    const isValid = this.state.reg.test(email);
                    if (!isValid) {
                        isError = true;
                    }
                    existingEmails.push(email);
                    // pokud je email validni, ale je jiz jednou na seznamu...
                    const ccount = existingEmails.filter(x => x === email).length;
                    if (ccount > 1) {
                        // Tento e-mail je již na seznamu.
                        sAction.dataSet(this.props.way + "/def/emails/" + i + "/error", sAction.translate("ERR_EMAIL_ALREADY_ON_LIST", "Emails"));
                        isError = true;
                    }
                } else if (isRequiredField && !existingEmails.length) {
                    isError = true;
                }
            }
        } else if (isRequiredField) {
            isError = true;
        }

        return isError;
    }

    deleteEmail = (e) => {
        const way = this.props.way;
        sAction.dsClear();
        sAction.dataDelete(way + "/def/emails", this.props.emailKey); //
        sAction.dsProcess();
        this.indicateChange();
    };

    unlockRecord() {
        sAction.dataSet(this.props.prefix + "/canSave", false);
    }

    componentWillUnmount() {
        const emails = sAction.dataGet(this.props.way + "/def/emails");
        const isRequiredField = this.props.data.def.get("required");
        if (isRequiredField) {
            return;
        }

        if (emails && emails.size) {
            let i = 0;
            for (let email of emails) {
                email = email.get("email_address");
                const isValid = this.state.reg.test(email);
                if (!isValid) {
                    sAction.dataDelete(this.props.way + "/def/emails", i);
                }
                i++;
            }
        }

        this.indicateChange();
    }

    keyDownCall = (e) => {
        // v situaci, kdy je email a relate pole po sobe a reagoval bych
        // na tab v key down, relatu by se spracoval nasledny keyup a vyskocilo z nej
        // proto se tady tab zamaskuje a zpracovava ve funkci zpracujici keyUp event
        if(e.keyCode === 9){
            e.preventDefault()
        }
    }

    render() {
        const data = this.props.data;
        var containerClass = "inputEditContainer";
        const email = this.props.email;
        let key = email.get("email_address_id");
        if (!key) key = email.get("email_address");

        let error = this.state.error;

        let emailAddress = email.get("email_address");
        const i = this.props.emailKey;
        return (
            <React.Fragment>
                    <div className="DetailViewValue__emails">
                        <div className={"EmailInputFieldRow"}>
                            <div className={containerClass} data-fieldname={data.name} data-fieldvalue={email.get("email_address")}>
                                <div className="inputContainer">
                                    <InputText
                                        onKeyUp={(e) => this.keyUpCall(e)}
                                        onKeyDown={(e) => this.keyDownCall(e)}
                                        autoFocus={i == 0 ? true : false}
                                        myRef={this.input}
                                        key={emailAddress}
                                        error={!!error}
                                        defaultValue={emailAddress}
                                        name={i.toString()}
                                        autoComplete="off"  // autocomplete prohlizece vypnout, aby se neprekryvali
                                    />
                                    {this.state.resultOpen ? <Autocomplete
                                        data={data}
                                        resultOpen={this.state.resultOpen}
                                        searchText={this.state.searchText}
                                        searchMethod={"email"}
                                        autocompleteReturnItem={this.storeEmail}
                                        ref={this.autocomplete}
                                    /> : null}

                                </div>
                                <div className="buttonContainer">
                                    {!!error &&
                                        (
                                            <div
                                                tabIndex="-1"
                                                className="warningMessage inputEditButton"
                                                onClick={() => this.setState({error: false})}
                                            >
                                                <Tooltip title={error} arrow open>
                                                    <div className="icon-warning" onMouseDown={this.storeEmail}/>
                                                </Tooltip>
                                            </div>

                                        )}
                                    <div tabIndex="0" className="inputEditButton" onMouseDown={this.storeEmail}>
                                        <div className="icon-detailConfrim"></div>
                                    </div>
                                    <div className=" inputEditButton">
                                        <div
                                            tabIndex="0"
                                            className={"icon-detailCancel"}
                                            onClick={this.deleteEmail}
                                            data-id={i}
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") {
                                                    this.deleteEmail(e);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Radio
                                checked={email.get("primary_address") === "1"}
                                onChange={(checked) =>
                                    this.onChange({
                                        target: {
                                            name: "PrimaryFlag",
                                            value: i,
                                            checked,
                                        },
                                    })
                                }
                            />
                            <TickBox
                                checked={email.get("opt_out") === "1"}
                                onChange={(checked) =>
                                    this.onChange({
                                        target: {name: "OptOutFlag", value: i, checked},
                                    })
                                }
                            />
                            <TickBox
                                checked={email.get("invalid_email") === "1"}
                                tabIndex={0}
                                onChange={(checked) =>
                                    this.onChange({
                                        target: {name: "InvalidFlag", value: i, checked},
                                    })
                                }
                            />
                        </div>
                    </div>
            </React.Fragment>
        );
    }
}
