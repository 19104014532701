export default function calInit(cssSelector, way, userID, language, from, to, _callback) {
  /**TODO udelat sekundární funkci, která bude pouze získávat eventy podle viditelného rozmezí v kalendáři
   * Nebude přerenderovávat kalendář, ale používat API kalendáře
   */

  this.calEvents.date_range.date_start = from
  this.calEvents.date_range.date_end = to
  this.calEvents.cssSelector = cssSelector
  if (language != undefined) {
    this.calEvents.language = language
  }

  const params = {
    "start": this.calEvents.date_range.date_start,
    "end": this.calEvents.date_range.date_end,
    "prefix": "calendar",
    "userID": way ? userID : '' // TODO vymyslet to jinak, bez pouziti tohoto parametru a hlavně ten bazmek na BE
  }

  if (_callback) { // pokud to neni jak predat, tak se da jedine prerenderovat kalendar a doufat, ze se nezacyklime
    this.calGetInitData(params, (self, force) => {
      self.calGetEvents(force, way, userID, (data) => {
        _callback(data)
      })
    })
  } else {
    this.calPlanReload()
  }
}
